import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Paper,
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL } from '../../constants';
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { PlacesTextField }from '../../components/Global/PlacesTextField';



export const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.longitude)

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    )
}
))


function EditLocation() {
    const { campaigns, getCampaigns } = useCampaigns();
    const { hash } = useParams();
    const [campaign, setCampaign] = useState();
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const history = useHistory();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;
    const [location, setLocation] = useState(undefined);
    const [locationError, setLocationError] = useState(undefined);


    useEffect(() => {
        var approved = campaigns?.filter(n => n.npo != null).filter(n => n.npo.id == USER.npoId).filter(a => a.hash == hash).find(f => f.hash)
        if(USER.role == 'NPO' & hash != approved?.hash){
            history.push('/app/error');
        } else {
            setCampaign(campaigns.map(campaign => campaign).find(c => c.hash === hash));
        }
    }, [campaigns])

    

    const initialValues = {
        id: campaign?.location?.id || '',
        createdAt: campaign?.location?.created_at || '',
        updatedAt: campaign?.location?.updated_at || '',
        name: campaign?.location?.name || '',
        address: campaign?.location?.address || '',
        latitude: campaign?.location?.lat || '',
        longitude: campaign?.location?.long || '',
        hosts: campaign?.hosts || [],
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        if(campaign?.location?.id !== undefined) {
            try {
                await axios.post(`${API_URL}/location/${values.id}`, location)
            } catch (e) {
                console.log('Failed to update data with error: ', e)
            }
        } else {
            try {
                await axios.post(`${API_URL}/create-location/${campaign.hash}`, location)
            } catch (e) {
                console.log('Failed to update data with error: ', e)
            }
    
        }

        setSubmitting(false);
        getCampaigns();
        history.goBack();
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Grid
                                container
                                spacing={3}
                                justifyContent={'center'}
                            >
                                <Dialog
                                    fullScreen={false}
                                    open={true}
                                    onClose={() => { history.goBack() }}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <Paper>
                                    <DialogTitle id="responsive-dialog-title">
                                        <IconButton
                                            color="inherit"
                                            onClick={() => history.goBack()}
                                            className={classNames(
                                                classes.headerMenuButton,
                                                classes.headerMenuButtonCollapse
                                            )}
                                            style={{ marginLeft: '0px' }}
                                        >
                                            <ArrowBackIcon
                                                classes={{
                                                    root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                }}
                                            />
                                        </IconButton>
                                        {"Edit Location"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <PlacesTextField 
                                                name="club" 
                                                label="Name of Golf Course" 
                                                selectedLocation={(l) => {
                                                    setLocation(l);
                                                    setLocationError(undefined);
                                                }} 
                                                error={locationError}
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Name"
                                                name="name"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.name : form.values.name}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Address"
                                                name="address"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.address : form.values.address}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Latitude"
                                                name="latitude"
                                                type="text"
                                                disabled
                                                // onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.lat : form.values.latitude}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Longitude"
                                                name="longitude"
                                                type="text"
                                                disabled
                                                // onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.long : form.values.longitude}
                                                variant="outlined"
                                            />
                                        </Box>
                                        {/* <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            {form.values.latitude !== '' &&
                                                <MyMapComponent
                                                    isMarkerShown
                                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAjdsiR85DQVbRG8xRLsjcLKQT8qsWUw3cw&v=3.exp&libraries=geometry,drawing,places"
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `400px` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                    latitude={parseFloat(form.values.latitude)}
                                                    longitude={parseFloat(form.values.longitude)}
                                                />
                                            }
                                        </Box> */}
                                        {/* <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }} >
                                            <TableContainer>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Typography variant={'h4'}>Hosts</Typography>
                                                </Box>
                                                <Table className={classes.table} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell align="left">Email</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {form.values.hosts?.map((h, key) => {
                                                                return (
                                                                    <TableRow className={classes.cell} key={key + h.name}>
                                                                        <TableCell component="th" scope="row">{h.name}</TableCell>
                                                                        <TableCell align="left">{h.email}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                            </TableContainer>
                                        </Paper> */}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => { history.goBack() }}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={form.isSubmitting || !location}
                                            onClick={() => {
                                                form.submitForm();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                    </Paper>
                                </Dialog>
                            </Grid>
                        </form>
                    )
                }
                }
            </Formik >
        </>
    )
}

export default EditLocation;