import React, { useState } from "react";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const AppContext = React.createContext();

export function AppProvider({ children }) {
  const [alert, setAlert] = useState(undefined);

  const closeAlert = () => {
    setAlert(undefined);
  };

  return (
    <AppContext.Provider value={{ alert, setAlert }}>
        {children}
        <Snackbar 
          open={alert != null} 
          autoHideDuration={6000} 
          onClose={closeAlert}
        >
            <Alert onClose={closeAlert} severity={alert?.type ?? 'success'}>
                {alert?.message}
            </Alert>
        </Snackbar>
    </AppContext.Provider>
  );
};