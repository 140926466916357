import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Paper,
  Toolbar,
  Box,
  Typography,
  Checkbox,
  makeStyles,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import {
  Check as CheckIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  itemContainer: {
    backgroundColor: theme.palette.background.default
  },
  title: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main
    }
  },
  image: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
   tableList: {
    borderBottom: "none",
    border: "none",
  },
  item: {
    '&:hover': {
      background: theme.palette.background.default
    },
    '&:active': {
      background: theme.palette.background.default
    }
  },
  sortButton: {
    textTransform: 'none',
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  },
  cardLink: {
    '&:hover': {
      textDecoration: 'none',
    }
  },
  cardItem: {
    transform: 'scale(0.95)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    }
  },
//   tableRow: {
//     cursor: 'pointer',
//     '&:hover': {
//       background: theme.palette.background.default
//     },
//   }
  tableRow: {
    background: theme.palette.divider
  }
}));


const StatusTable = ({dataRows}) => {
  const [rows, setRows] = React.useState(dataRows);
  const classes = useStyles();

    function getTime(time) {
        const timestamp = time;
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleString();
        return formattedDate
    }
  return (
    <Box style={{maxWidth: '100%', maxHeight: '300px', overflowY: 'scroll'}}>
      <Paper >
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer >
          <Table>
            <TableHead>
                <TableRow style={{backgroundColor: '#F6F7FF'}}>
                    <TableCell>Status</TableCell>
                    <TableCell>Reschedule Reason</TableCell>
                    <TableCell>Time</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {dataRows ? dataRows.sort((a,b) => {
                const aTime = new Date(a.created_at);
                const bTime = new Date(b.created_at)
                return bTime - aTime
              })
                .map((row, index) => {
                  return (
                    <TableRow  key={`table_${index}`} className={index % 2 === 0 ? classes.tableRow : null}  onClick={() => {
                    //   history.push(`/app/campaigns/${row?.hash}`)
                    }}>
                        <TableCell className={classes.tableList} style={{paddingTop:'0px'}}>{row?.status} </TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.rescheduleReason}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{getTime(row?.created_at)}</TableCell>
                    </TableRow>
                   
                  );
                })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default StatusTable;
