import React, { useState, useEffect } from 'react';
import {
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Box,
    Typography,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import useStyles from './styles';
import {
    CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { useStyles as TableStyles } from "../../themes/TableStyles";
import AddWinner from '../../pages/Campaigns/addWinner';


const CsvResults = ({ npoCampaigns, csvData, setImportData }) => {
    const classes = useStyles();
    const tableClass = TableStyles();
    const [mergedData, setMergedData] = useState();
    const [unmatchedData, setUnmatchedData] = useState([]);
    const [showAddWinnerData, setShowAddWinnerData] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState();
    const [campaignsWithWinners, setCampaignsWithWinners] = useState([])
    const [allCampaigns, setAllCampaigns] = useState()
    const [duplicateHosts, setDuplicateHosts] = useState([])
    const [manualUpdateType, setManualUpdateType] = useState()
    useEffect(() => {
        setAllCampaigns(npoCampaigns)
    },[])

     console.log('npoCampaigns', npoCampaigns)

    const joinData = (csvData) => {
        let matchedData = [];
        let unmatched = [];
        let withWinners = [];
        let duplicateHosts = [];

        const headerRow = csvData.data[0];
        const trimmed = headerRow.map(r => r.trim());
        const itemDonor = trimmed.indexOf('Item Donated By');
        const itemHash = trimmed.indexOf('Item Reference Number');
        const itemWinnerColumn = csvData.data[0].indexOf('Name');
        for (const line of csvData.data) {
            for (const campaign of allCampaigns) {

                if(campaign.hosts.length <= 0) {
                    continue
                }

                if (!line[itemWinnerColumn] || line[itemWinnerColumn] === undefined) {
                    continue;
                }
  
                // line[itemDonor] === campaign.hosts[0].email.toString() || 
                if (line[itemHash].trim() === campaign.hash) {
                    if (!matchedData.find(m => m.id === campaign.id) && campaign.winners.length <= 0) {
                        
                        // eslint-disable-next-line no-undef
                        let localCampaign = structuredClone(campaign)
                        const winner = {
                            name: line[0],
                            email: line[1],
                            phone: line[2],
                            campaignId: localCampaign.id,
                        }
                        localCampaign.winners[0] = winner
                        matchedData.push(localCampaign)
                    } else if (!withWinners.find(m => m.id === campaign.id) && campaign.winners.length > 0 && !matchedData.find(m => m.id === campaign.id))  {
                        withWinners.push(campaign)
                    }
                } else {
                    if (campaign) {
                        if (!unmatched.find(u => u.id === campaign.id) && campaign.winners.length <= 0) {
                            unmatched.push(campaign)
                        } else if (!withWinners.find(u => u.id === campaign.id) && campaign.winners.length > 0 && !unmatched.find(u => u.id === campaign.id))  {
                            withWinners.push(campaign)
                        }
                    }
                }
            }
        }

        for (const m of matchedData) {
            for (const u of unmatched) {
                const index = unmatched.indexOf(u)
                if (m.id === u.id) {
                    unmatched.splice(index, 1)
                }
            }
        }
        // for (const m of matchedData) {
        //     for (const d of matchedData) {
        //         const index = matchedData.indexOf(d)
        //         const index2 = matchedData.indexOf(m)
        //         if (d.hosts[0]?.email === m.hosts[0]?.email && d.id !== m.id) {
        //             d.winners[0].name = ''
        //             d.winners[0].email = ''
        //             d.winners[0].phone = ''
        //             duplicateHosts.push(d)
        //             matchedData.splice(index, 1)
        //             m.winners[0].name = ''
        //             m.winners[0].email = ''
        //             m.winners[0].phone = ''
        //             duplicateHosts.push(m)
        //             matchedData.splice(index2, 1)
        //         }
        //     }
        // }

        for (const u of unmatched) {
            const index = unmatched.indexOf(u)
            for (const w of withWinners) {
                if (w.id === u.id) {
                    unmatched.splice(index, 1)
                }
            }
        }
        setMergedData(matchedData)
        setUnmatchedData(unmatched);
        setDuplicateHosts(duplicateHosts)
        setCampaignsWithWinners(withWinners)
        setImportData(matchedData)
    }

    useEffect(() => {
        if (allCampaigns && csvData) {
            joinData(csvData)
        }
    }, [allCampaigns,csvData])

    const setShowAddWinner = (value) => {
        setShowAddWinnerData(value)
    }

    const manualAddWinnerData = (winnerData, campaignId, type) => {
        let campaignToUpdate
        if (type === 'unmatched'){
            campaignToUpdate = unmatchedData.find(u => u.id === campaignId)
        } else if (type === 'duplicateHost') {
            campaignToUpdate = duplicateHosts.find(u => u.id === campaignId)
        }
        
        campaignToUpdate.winners[0] = {
            name: winnerData.name,
            email: winnerData.email,
            phone: winnerData.phone,
            campaignId: campaignId
        }
        mergedData.push(campaignToUpdate)
        unmatchedData.splice(campaignToUpdate, 1)
    }

    return (
        <>
            <Dialog
                fullScreen={false}
                open={showAddWinnerData}
                onClose={() => {setShowAddWinnerData(false)}}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                // maxWidth={csvData ? 'lg' : 'sm'}
            >
                <DialogContent>
                    <AddWinner setShowAddWinner={setShowAddWinner} campaignId={selectedCampaign} setWinnerData={manualAddWinnerData} type={manualUpdateType} />
                </DialogContent>
            </Dialog>
            <TableContainer className={tableClass.container}>
                <Box
                    mt={3}
                    mb={1}
                >
                    <Typography variant={'h4'}>Results</Typography>
                </Box>
                <Table className={classes.table} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Match Found</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Location</TableCell>
                            <TableCell align="left">Host Name</TableCell>
                            <TableCell align="left">Host Email</TableCell>
                            <TableCell align="left">Winner Name</TableCell>
                            <TableCell align="left">Winner Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mergedData?.map((c, key) => {
                            return (
                                <TableRow className={tableClass.mergedCell} key={key} >
                                    <TableCell component="th" scope="row" style={{ color: '#428e75' }}><CheckCircleIcon /></TableCell>
                                    <TableCell align="left">{c.name}</TableCell>
                                    <TableCell align="left">{c.location?.name}</TableCell>
                                    <TableCell align="left">{c.hosts[0]?.name}</TableCell>
                                    <TableCell align="left">{c.hosts[0]?.email}</TableCell>
                                    <TableCell align="left">{c.winners[0]?.name}</TableCell>
                                    <TableCell align="left">{c.winners[0]?.email}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {duplicateHosts.length > 0 ? (
                    <>
                        <Typography style={{ marginTop: 30 }} variant={'h5'}>The following campaigns have the same host</Typography>
                        <Typography>Click to add winner data</Typography>
                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Location</TableCell>
                                    <TableCell align="left">Host Name</TableCell>
                                    <TableCell align="left">Host Email</TableCell>
                                    <TableCell align="left">Winner Name</TableCell>
                                    <TableCell align="left">Winner Email</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {duplicateHosts?.map((c, key) => {
                                    return (
                                        <TableRow className={tableClass.cell} key={key} onClick={() => {setManualUpdateType('duplicateHost'); setSelectedCampaign(c.id); setShowAddWinnerData(true)}} >
                                            <TableCell component="th" scope="row">{c.name}</TableCell>
                                            <TableCell align="left">{c.location?.name}</TableCell>
                                            <TableCell align="left">{c.hosts[0]?.name}</TableCell>
                                            <TableCell align="left">{c.hosts[0]?.email}</TableCell>
                                            <TableCell align="left">{c.winners[0]?.name}</TableCell>
                                            <TableCell align="left">{c.winners[0]?.email}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </>
                ) : null}
                {unmatchedData.length > 0 ? (
                    <>
                        <Typography style={{ marginTop: 30 }} variant={'h5'}>The following campaigns had no match from csv data</Typography>
                        <Typography>Click to add winner data</Typography>
                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Location</TableCell>
                                    <TableCell align="left">Host Name</TableCell>
                                    <TableCell align="left">Host Email</TableCell>
                                    <TableCell align="left">Winner Name</TableCell>
                                    <TableCell align="left">Winner Email</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {unmatchedData?.map((c, key) => {
                                    return (
                                        <TableRow className={tableClass.cell} key={key} onClick={() => {setManualUpdateType('unmatched'); setSelectedCampaign(c.id); setShowAddWinnerData(true)}} >
                                            <TableCell component="th" scope="row">{c.name}</TableCell>
                                            <TableCell align="left">{c.location?.name}</TableCell>
                                            <TableCell align="left">{c.hosts[0]?.name}</TableCell>
                                            <TableCell align="left">{c.hosts[0]?.email}</TableCell>
                                            <TableCell align="left">{c.winners[0]?.name}</TableCell>
                                            <TableCell align="left">{c.winners[0]?.email}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </>
                ) : null}
                {campaignsWithWinners.length > 0 ? (
                    <>
                        <Typography style={{ marginTop: 30 }} variant={'h5'}>The following campaigns already have winner data</Typography>
                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Location</TableCell>
                                    <TableCell align="left">Host Name</TableCell>
                                    <TableCell align="left">Host Email</TableCell>
                                    <TableCell align="left">Winner Name</TableCell>
                                    <TableCell align="left">Winner Email</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {campaignsWithWinners?.map((c, key) => {
                                    return (
                                        <TableRow className={tableClass.mergedCell} key={key} >
                                            <TableCell component="th" scope="row">{c.name}</TableCell>
                                            <TableCell align="left">{c.location?.name}</TableCell>
                                            <TableCell align="left">{c.hosts[0]?.name}</TableCell>
                                            <TableCell align="left">{c.hosts[0]?.email}</TableCell>
                                            <TableCell align="left">{c.winners[0]?.name}</TableCell>
                                            <TableCell align="left">{c.winners[0]?.email}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </>
                ) :null}
            </TableContainer>
        </>
    )
}

export default CsvResults;