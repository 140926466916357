import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  // useLocation,
  useHistory
} from "react-router-dom";
// import jwt from "jsonwebtoken";
import axios from "axios";
import { signOut } from '../context/UserContext';

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState, useUserDispatch } from "../context/UserContext";
import { CampaignProvider }from '../context/CampaignContext';
import EditCampaign from "../pages/Campaigns/EditCampaign";
// import Pusher from 'pusher-js';
// import Sockets from '../Sockets';

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: 'EQTqQKIOMW', // hard code
//   wsHost: process.env.REACT_APP_WEBSOCKET_HOST, 
//   enabledTransports: ['ws'],
//   wsPort:Number(process.env.REACT_APP_WEBSOCKET_PORT),
//   forceTLS: false,
//   disableStats: false
// });


const api = (userDispatch, history) => {
  // Set Base URL
  // axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}api/v1`;
    axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}api/`;
  // Check localstorage for auth token and orgId
  axios.interceptors.request.use(
    (config) => {
      const localStorageData = localStorage.getItem('Member for a Day');

      if (localStorageData) {
        const { token } = JSON.parse(localStorageData);

        if (token) {
          config.headers.common["Authorization"] = `Bearer ${token}`;
          config.headers.common["Organization-Id"] = 1;
        } else {
          config.headers.common["Authorization"] = null;
          config.headers.common["Organization-Id"] = null;
        }
      }

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Do something with response error
    if (error?.response?.status === 401) {
        console.log('unauthorized, logging out ...');

        signOut(userDispatch, history);
    }
    return Promise.reject(error.response);
});

  // Handle Errors
  // axios.interceptors.response.use(
  //   function(response) {
  //     // Do something with response data
  //     return response;
  //   },
  //   function(error) {
  //     // Do something with response error
  //     console.log(`failed to make network request with error: `, error);

  //     if (
  //       error &&
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.message
  //     ) {
  //       return Promise.reject({
  //         status: error.response.data.status,
  //         errorMessage: error.response.data.message
  //       });
  //     }

  //     return Promise.reject({
  //       status: "failed",
  //       errorMessage: "An unknown error occurred. Please contact support"
  //     });
  //   }
  // );
};




const Routes = () => {
  const history = useHistory();
  var { isAuthenticated } = useUserState();
  var userDispatch = useUserDispatch();


  useMemo(() => {
    api(userDispatch, history);
  });

  function PrivateRoute({ component, ...rest }) {
    // const token = localStorage.getItem("token");
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect to={"/login"} />
          )
        }
      />
    );
  }
  
  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to="/app/campaigns" />}
      />
      
      <Route path="/app/error" component={Error} />
      <CampaignProvider>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/app" component={Layout} />
        {/* <PrivateRoute path="/app/campaigns" component={Layout} /> */}
        {/* <PrivateRoute path="/app/campaigns/:hash" component={Layout}/> */}
      </CampaignProvider>
      <Route component={Error} />
    </Switch>
  );
}

export default function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );

  // #######################################################################

}
