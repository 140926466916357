import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    Paper,
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    Checkbox,
    Divider,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Collapse,
    Switch,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import useStyles from '../Locations/styles';
import { API_URL } from '../../constants';

export const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.longitude)

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    )
}
))


function AddWinner({showAddWinner, setShowAddWinner, winnerData, setWinnerData, campaignId, type}) {
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const [contactPersonOpen, setContactPersonOpen] = useState(false);




    const initialValues = {
        name: winnerData ? winnerData.name :'',
        email: winnerData ? winnerData.email : '',
        phone: winnerData ? winnerData.phone : '',
        contactPersonName: winnerData ? winnerData.contactPersonName : '',
        contactPersonEmail: winnerData ? winnerData.contactPersonEmail : '',
        contactPersonPhone: winnerData ? winnerData.contactPersonPhone : '',
        clubRelation: winnerData ? winnerData.clubRelation : '',
        memberOfClub: winnerData ? winnerData.memberOfClub : false,
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)
        setWinnerData(values, campaignId, type)
        setShowAddWinner(false)
        setSubmitting(false);
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Grid
                                container
                                spacing={3}
                                justifyContent={'center'}
                            >
                                <Dialog
                                    fullScreen={false}
                                    open={true}
                                    onClose={() => { setShowAddWinner(false) }}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <Paper>
                                        <DialogTitle id="responsive-dialog-title">
                                            <IconButton
                                                color="inherit"
                                                onClick={() => setShowAddWinner(false)}
                                                className={classNames(
                                                    classes.headerMenuButton,
                                                    classes.headerMenuButtonCollapse
                                                )}
                                                style={{ marginLeft: '0px' }}
                                            >
                                                <ArrowBackIcon
                                                    classes={{
                                                        root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                    }}
                                                />
                                            </IconButton>
                                            {"Edit Winner"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="filled"
                                                        name="name"
                                                        label="Full Name"
                                                        value={form.values.name}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        error={Boolean(form.errors.name && form.touched.name)}
                                                        helperText={form.errors.name && form.touched.name && String(form.errors.name)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        variant="filled"
                                                        name="phone"
                                                        label="Phone"
                                                        value={form.values.phone}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        error={Boolean(form.errors.phone && form.touched.phone)}
                                                        helperText={form.errors.phone && form.touched.phone && String(form.errors.phone)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="filled"
                                                        name="email"
                                                        label="Email"
                                                        value={form.values.email}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        error={Boolean(form.errors.email && form.touched.email)}
                                                        helperText={form.errors.email && form.touched.email && String(form.errors.email)}
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => { setShowAddWinner(false) }}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color="primary"
                                                disabled={form.isSubmitting || (!form.values.email || !form.values.name)}
                                                onClick={() => {
                                                    form.submitForm();
                                                }}
                                            >
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Paper>
                                </Dialog>
                            </Grid>
                        </form>
                    )
                }
                }
            </Formik >
        </>
    )
}

export default AddWinner;