import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import classnames from 'classnames'
// import Icon from '@mdi/react'
// import {
//     mdiSettings as SettingsIcon,
//     mdiFacebookBox as FacebookIcon,
//     mdiTwitterBox as TwitterIcon,
//     mdiGithubBox as GithubIcon,
// } from '@mdi/js'
import {
    IconButton,
    Box,
    Grid,
    Breadcrumbs,
    Tabs,
    Tab,
} from '@material-ui/core'
import {
    NavigateNext as NavigateNextIcon,
    CalendarToday as CalendarIcon,
    ChatBubbleOutline as ChatIcon,
    AddShoppingCart as AddIcon,
    StarBorder as StarIcon,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'

// styles
import useStyles from './styles'

// components
import Header from '../Header'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import Widget from '../Widget'
import { Link, Typography, Button } from '../../components/Wrappers'
import { useUserState } from '../../context/UserContext';


// pages
import Dashboard from '../../pages/dashboard'
import Organizations from '../../pages/organizations';
import AllCampaigns from '../../pages/Campaigns/AllCampaigns';
import CreateCampaign from '../../pages/Campaigns/CreateCampaign'
import AllLocations from '../../pages/Locations/AllLocations'
import EditLocation from '../../pages/Locations/EditLocation'
import AllNPOs from '../../pages/NPOs/AllNPOs';
import EditCampaign from '../../pages/Campaigns/EditCampaign';
import Users from '../../pages/users';
import Logs from '../../pages/logs';
import Chat from '../../pages/chat/Chat';
import Error from '../../pages/error/Error';
import CreateNewNpo from '../../pages/NPOs/CreateNewNpo';
import Calendar from '../../pages/calendar/Calendar'

// context
import { useLayoutState } from '../../context/LayoutContext';

// import { ProductsProvider } from '../../context/ProductContext'

//Sidebar structure
import structure from '../Sidebar/SidebarStructure'
// Tab styling

const CustomTab = withStyles(theme => ({
    root: {
        minWidth: 72,
        textTransform: 'none',
        fontWeight: 400,
    },
}))(props => <Tab {...props} />)

function Layout(props) {
    const classes = useStyles()
    const [value, setValue] = React.useState(2)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const open = Boolean(anchorEl)
    const id = open ? 'add-section-popover' : undefined
    // const handleClick = event => {
    //     setAnchorEl(open ? null : event.currentTarget)
    // }

    // global
    var layoutState = useLayoutState()

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <div className={classes.root}>
            <Header history={props.history} />
            <Sidebar structure={structure} />
            <div
                className={classnames(classes.content, {
                    [classes.contentShift]: layoutState.isSidebarOpened,
                })}
            >
                <div className={classes.fakeToolbar} />
                {/* <Widget
                    disableWidgetMenu
                    inheritHeight
                    className={classes.margin}
                    bodyClass={classes.navPadding}
                >
                </Widget> */}
                <Switch>
                    <Route exact={true} path="/app/campaigns" component={AllCampaigns} />
                    {/* <Route path="/app/campaigns/:hash" component={EditCampaign} /> */}
                    <Route exact={true} path="/app/campaigns/create" component={CreateCampaign} />
                    <Route path="/app/campaigns/:hash" component={EditCampaign} />
                    <Route path="/app/locations" component={AllLocations} />
                    {/* <Route exact={true} path="/app/locations/edit/:hash?" component={EditLocation} /> */}
                    <Route exact={true} path="/app/npos" component={AllNPOs} />
                    <Route exact={true} path="/app/npos/edit" component={CreateNewNpo} />
                    <Route path="/app/npos/edit/:hash" component={CreateNewNpo} />
                    <Route path="/app/calendar" component={Calendar} />
                    {/* <Route path="/app/users" component={Users} />
                    <Route path="/app/logs" component={Logs} />
                    <Route path="/app/chat" component={Chat} /> */}
                    {/* <Route
                        path="/app/core/typography"
                        component={TypographyPage}
                    />
                    <Route path="/app/core/grid" component={GridPage} />
                    <Route
                        path="/app/ui/notifications"
                        component={Notifications}
                    />
                    <Route
                        path="/app/forms/elements"
                        component={FormsElements}
                    />
                    <Route
                        path="/app/forms/validation"
                        component={FormValidation}
                    />
                    <Route path="/app/ui/badge" component={Badge} />
                    <Route path="/app/ui/carousel" component={Carousel} />
                    <Route path="/app/ui/modal" component={Modal} />
                    <Route path="/app/ui/navbar" component={Navbar} />
                    <Route path="/app/ui/tooltips" component={Tooltips} />
                    <Route path="/app/ui/tabs" component={TabsPage} />
                    <Route path="/app/ui/cards" component={Cards} />
                    <Route path="/app/ui/widget" component={WidgetPage} />
                    <Route path="/app/ui/progress" component={Progress} />
                    <Route path="/app/tables/static" component={Tables} />
                    <Route
                        path="/app/tables/dynamic"
                        component={DynamicTables}
                    />
                    <Route path="/app/charts/overview" component={Charts} />
                    <Route path="/app/charts/line" component={LineCharts} />
                    <Route path="/app/charts/bar" component={BarCharts} />
                    <Route path="/app/charts/pie" component={PieCharts} />
                    <Route path="/app/ecommerce/management" exact>
                        <ProductsProvider>
                            <Ecommerce />
                        </ProductsProvider>
                    </Route>
                    <Route path="/app/ecommerce/management/edit/:id" exact>
                        <ProductsProvider>
                            <CreateProduct />
                        </ProductsProvider>
                    </Route>
                    <Route path="/app/ecommerce/management/create">
                        <ProductsProvider>
                            <CreateProduct />
                        </ProductsProvider>
                    </Route>
                    <Route
                        path="/app/ecommerce/product/:id"
                        component={Product}
                    />
                    <Route path="/app/ecommerce/product" component={Product} />
                    <Route
                        path="/app/ecommerce/gridproducts"
                        component={ProductsGrid}
                    />
                    <Route
                        exact
                        path="/app/tables"
                        render={() => <Redirect to={'/app/tables/static'} />}
                    />
                    <Route
                        exact
                        path="/app/charts"
                        render={() => <Redirect to={'/app/charts/overview'} />}
                    />
                    <Route
                        exact
                        path="/app/ui"
                        render={() => <Redirect to="/app/ui/icons" />}
                    />
                    <Route
                        exact
                        path="/app/core"
                        render={() => <Redirect to="/app/core/typography" />}
                    />
                    <Route
                        exact
                        path="/app/forms"
                        render={() => <Redirect to="/app/forms/elements" />}
                    />
                    <Route
                        exact
                        path="/app/ecommerce"
                        render={() => (
                            <Redirect to="/app/ecommerce/management" />
                        )}
                    />
                    <Route
                        exact
                        path="/app/extra"
                        render={() => <Redirect to="/app/extra/timeline" />}
                    />
                    <Route
                        exact
                        path="/app/maps"
                        render={() => <Redirect to="/app/maps/google" />}
                    />
                    <Route path="/app/extra/timeline" component={Timeline} />
                    <Route path="/app/extra/search" component={Search} />
                    <Route path="/app/extra/gallery" component={Gallery} />
                    <Route path="/app/extra/invoice" component={Invoice} />
                    <Route path="/app/extra/calendar" component={Calendar} />
                    <Route path="/app/core/colors" component={Colors} />
                    <Route path="/app/maps/google" component={MapsGoogle} />
                    <Route path="/app/maps/vector" component={VectorMaps} />
                    <Route path="/app/ui/icons" component={Icons} />
                    <Route path={'/app/user'}>
                        <Redirect to={'/app/user/list'} />
                    </Route>
                    <Route path={'/app/user/list'}>
                        <UserList />
                    </Route> */}
                </Switch>
                {/* <Fab
                    color="primary"
                    aria-label="settings"
                    onClick={e => handleClick(e)}
                    className={classes.changeThemeFab}
                    style={{ zIndex: 100 }}
                >
                    <Icon path={SettingsIcon} size={1} color="#fff" />
                </Fab>
                <ColorChangeThemePopper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                /> */}
                <Footer>
                    <div>
                        {/* <Link
                            color={'primary'}
                            href={'https://sympler.co/'}
                            target={'_blank'}
                            className={classes.link}
                        >
                            Sympler
                        </Link> */}
                        {/* <Link
                            color={'primary'}
                            href={'https://flatlogic.com/about'}
                            target={'_blank'}
                            className={classes.link}
                        >
                            About Us
                        </Link>
                        <Link
                            color={'primary'}
                            href={'https://flatlogic.com/blog'}
                            target={'_blank'}
                            className={classes.link}
                        >
                            Blog
                        </Link> */}
                    </div>
                    <div>
                        {/* <Link
                            href={'https://www.facebook.com/flatlogic'}
                            target={'_blank'}
                        >
                            <IconButton aria-label="facebook">
                                <Icon
                                    path={FacebookIcon}
                                    size={1}
                                    color="#6E6E6E99"
                                />
                            </IconButton>
                        </Link>
                        <Link
                            href={'https://twitter.com/flatlogic'}
                            target={'_blank'}
                        >
                            <IconButton aria-label="twitter">
                                <Icon
                                    path={TwitterIcon}
                                    size={1}
                                    color="#6E6E6E99"
                                />
                            </IconButton>
                        </Link>
                        <Link
                            href={'https://github.com/flatlogic'}
                            target={'_blank'}
                        >
                            <IconButton
                                aria-label="github"
                                style={{ padding: '12px 0 12px 12px' }}
                            >
                                <Icon
                                    path={GithubIcon}
                                    size={1}
                                    color="#6E6E6E99"
                                />
                            </IconButton>
                        </Link> */}
                    </div>
                </Footer>
            </div>
        </div>
    )
}

export default withRouter(Layout)
