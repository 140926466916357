import React from 'react';
import {
  Button,
  makeStyles,
  Input,
} from '@material-ui/core';



const useStyles = makeStyles(() => ({
  root: {},
  fileImage: {
    maxWidth: 300,
    objectFit: 'cover',
  },
  uploadArea: {
    display: 'inline-block',
    position: 'relative',
    minHeight: '100px',
    width: '100%',
    '& div': {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
    },
    '& input': {
      position: 'absolute',
      left: 0,
      opacity: 0,
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
    }
  },
  uploadedImage: {
    position: 'relative',
    top: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0) !important',
    // color: theme.palette.primary.light,
    // border: `1px dashed ${theme.palette.divider}`,
    borderRadius: '10px',
    textAlign: 'center',
    paddingTop: '27px',
    paddingBottom: '20px'
  },
  previewSpan: {
    zIndex: 1000000,
  }
}));

const FileUpload = ({ onDrop, onChange, ImageThumb, file, accept, disabled }) => {
  const classes = useStyles();

  // const vidPlay = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
  //   const video = e.currentTarget.querySelector('video')
  //   if (video && !video.play()) {
  //     video.play();
  //   }
  // }

  // const vidPause = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
  //   const video = e.currentTarget.querySelector('video')
  //   if (video && video.play()) {
  //     video.pause();
  //   }
  // }
  return (

    <label htmlFor="contained-button-file" className={classes.uploadArea}>
      <Button variant="contained" component="span" disableRipple className={classes.uploadedImage}>
        <span>
          <ImageThumb image={file ?? ''} />
          {!file && <p>Drag and drop or click to select file</p>}
          {file && <p>Filename: {file?.name ?? ''}</p>}
          {file && <p>Drag and drop or click to change file</p>}
        </span>
      </Button>
      <input id="contained-button-file" type="file" accept={accept} style={{ opacity: 0 }} disabled={disabled} onDrop={onDrop} onChange={onChange} />
    </label>

  );
};


export default FileUpload;