import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    Collapse,
    Tooltip,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { 
    ExpandMore as ExpandMoreIcon,
    AddCircle,
    Delete
} from '@material-ui/icons';
import axios from "axios";
import { AppContext } from '../../context/AppContext'; 

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const StudyCSVColumnDialog = ({ column, study, open, toggle, setColumnName }) => {
    const [name, setName] = useState();
    const [order, setOrder] = useState();

    useEffect(() => {
        setName(column?.column ?? '');
        setOrder(column?.order?.toString() ?? '0');
    }, [column]);

    return (
        <Dialog open={open} onClose={toggle} maxWidth="sm">
            <DialogTitle>{column ? `Column ${column.id}` : 'Add New Column'}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField label="Order" fullWidth value={order} onChange={(e) => setOrder(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Column Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => setColumnName(study, { ...column, column: name, order: order ? Number(order) : 0 })}>Save</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

const StudyFilterDetail = ({ filter, study, open, toggle, reload, setError }) => {
    const appCtx = useContext(AppContext);
    const [columnName, setColumnName] = useState('');
    const [displayName, setDisplayName] = useState('');

    useEffect(() => {
        setColumnName(filter?.columnName ?? '');
        setDisplayName(filter?.displayName ?? '');
    }, [filter, open]);

    const _toggle = () => {
        setColumnName('');
        setDisplayName('');
        toggle();
    }
    
    const handleSubmit = async () => {
        const postData = {
            'id': filter?.id,
            'column_name': columnName,
            'display_name': displayName,
            'study_id': study.id
        };

        axios.post('updatestudyfilter', postData).then(result => {
            if (result.data && result.data.response && result.data.response) {
                reload();
                _toggle();

                appCtx.setAlert({ type: 'success', message: 'Updated filters' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to update filters' });
            }
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to update filters' });
        });
    };

    return (
        <Dialog open={open} onClose={toggle} maxWidth="sm">
            <DialogTitle>{filter ? `Filter ${filter.id}` : 'Add New Filter'}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TextField label="Column Name" fullWidth value={columnName} onChange={(e) => setColumnName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Display Name" fullWidth value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleSubmit}>Save</Button>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export const StudyDetail = ({ open, toggle, organization, reload, study }) => {
    const classes = useStyles();
    
    const appCtx = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);
    const [studyName, setStudyName] = useState(study ? study.name : '');
    const [csvId, setCsvId] = useState(study ? study['google_csv_id'] : '');
    const [rangeStart, setRangeStart] = useState(study ? study['google_range_start'] : '');
    const [rangeEnd, setRangeEnd] = useState(study ? study['google_range_end'] : '');
    const [displayName, setDisplayName] = useState(study ? study['display_name'] : '');
    const [locationName, setLocationName] = useState(study ? study['location_name'] : '');
    const [topicsRangeStart, setTopicsRangeStart] = useState(study ? study['google_topics_range_start'] : '');
    const [topicsRangeEnd, setTopicsRangeEnd] = useState(study ? study['google_topics_range_end'] : '');
    const [questionsCsvId, setQuestionsCsvId] = useState(study ? study['question_data_csv_id'] : '');
    const [questionsRangeStart, setQuestionsRangeStart] = useState(study ? study['question_data_start_range'] : '');
    const [questionsRangeEnd, setQuestionsRangeEnd] = useState(study ? study['question_data_end_range'] : '');
    const [csvPath, setCsvPath] = useState(study ? study['local_csv_path'] : '');
    const [sortOrder, setSortOrder] = useState(study ? study['sortOrder'] : '');
    const [deleteStudyDialog, setDeleteStudyDialog] = useState(false);
    const [deleteFilterDialog, setDeleteFilterDialog] = useState(false);
    const toggleDeleteFilterDialog = () => setDeleteFilterDialog(!deleteFilterDialog);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const toggleFilters = () => setFiltersOpen(!filtersOpen);
    const [columnsOpen, setColumnsOpen] = useState(false);
    const toggleColumnsOpen = () => setColumnsOpen(!columnsOpen);
    const [secondarySheetsOpen, setSecondarySheetsOpen] = useState(false);
    const toggleSecondarySheetsOpen = () => setSecondarySheetsOpen(!secondarySheetsOpen);
    const [studyFilterOpen, setStudyFilterOpen] = useState(false);
    const toggleStudyFilter = () => setStudyFilterOpen(!studyFilterOpen);
    const [columnDialogOpen, setColumnDialogOpen] = useState(false);
    const toggleColumnDialog = () => setColumnDialogOpen(!columnDialogOpen);
    const [selectedStudyColumn, setSelectedStudyColumn] = useState();
    const [selectedStudyFilter, setSelectedStudyFilter] = useState();
    const [selectedDeleteFilter, setSelectedDeleteFilter] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        setStudyName(study?.name ?? '');
        setCsvId(study ? study['google_csv_id'] : '');
        setRangeStart(study ? study['google_range_start'] : '');
        setRangeEnd(study ? study['google_range_end'] : '');
        setTopicsRangeStart(study ? study['google_topics_range_start'] : '');
        setTopicsRangeEnd(study ? study['google_topics_range_end'] : '');
        setQuestionsCsvId(study ? study['question_data_csv_id'] : '');
        setQuestionsRangeStart(study ? study['question_data_start_range'] : '');
        setQuestionsRangeEnd(study ? study['question_data_end_range'] : '');
        setCsvPath(study ? study['local_csv_path'] : '');
        setSortOrder(study ? Number(study['sortOrder']) : 0);
        setColumns(study?.columns);
        setDisplayName(study ? study['display_name'] : '');
        setLocationName(study ? study['location_name'] : '');
    }, [study, open]);

    const _toggle = () => {
        setError('');
        setStudyName('');
        setCsvId('');
        setRangeStart('');
        setRangeEnd('');
        setTopicsRangeEnd('');
        setTopicsRangeStart('');
        setQuestionsCsvId('');
        setQuestionsRangeStart('');
        setQuestionsRangeEnd('');
        setDisplayName('');
        setLocationName('');
        setCsvPath('');
        setSortOrder(0);
        setColumns(undefined);

        toggle();
    };

    const handleSubmit = () => {
        let e = undefined;
        if (studyName.length <= 0) {
            e = 'Please enter an organization name';
        } else if (csvId.length <= 0) {
            e = 'Please enter google sheet id';
        } else if (rangeStart.length <= 0) {
            e = 'Please enter the sheet range start';
        } else if (rangeEnd.length <= 0) {
            e = 'Please enter the sheet range end';
        }

        if (e) {
            setError(e);
        } else {
            _submit();
        }
    };

    const handleDeleteFilter = async (filter) => {
        setSelectedDeleteFilter(filter);
        toggleDeleteFilterDialog();
    };

    const handleDeleteColumn = async (column) => {
        const newColumns = columns ? [...columns].filter(c => c.id !== column.id) : [];
        setColumns(newColumns);
    };

    const setColumnName = (study, column) => {
        const newColumns = columns ? [...columns] : [];
        if (column.id) {
            for (const c of newColumns) {
                if (c.id === column.id) {
                    c.column = column.column;
                    c.order = column.order;
                }
            }
        } else {
            newColumns.push(column);
        }
        setColumns(newColumns.sort((a,b) => a.order - b.order));

        toggleColumnDialog();
    };

    const deleteStudy = () => {
        setDeleteStudyDialog(!deleteStudyDialog);
    };

    const _deleteStudy = async () => {
        axios.delete(`study/${study.id}`).then(result => {
            if (result.data && result.data.response === true) {
                setDeleteStudyDialog(!deleteStudyDialog);
                reload();
                _toggle();
                
                appCtx.setAlert({ type: 'success', message: 'Deleted Study' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to delete study' });
            }
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to delete study' });
        });
    }

    const _deleteFilter = async () => {
        if (!selectedDeleteFilter) return;

        axios.delete(`removestudyfilter/${selectedDeleteFilter.id}`).then(result => {
            if (result.data && result.data.response && result.data.response) {
                setSelectedDeleteFilter(undefined);
                reload();
                toggleDeleteFilterDialog();
                _toggle();

                appCtx.setAlert({ type: 'success', message: 'Deleted Filter' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to delete filter' });
            }
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to delete filter' });
        });

        
    };

    const _submit = async () => {
        setLoading(true);

        const postData = {
            'id': study?.id,
            'name': studyName,
            'google_csv_id': csvId,
            'google_range_start': rangeStart,
            'google_range_end': rangeEnd,
            'local_csv_path': csvPath,
            'question_data_csv_id': questionsCsvId,
            'question_data_start_range': questionsRangeStart,
            'question_data_end_range': questionsRangeEnd,
            'google_topics_range_start': topicsRangeStart,
            'google_topics_range_end': topicsRangeEnd,
            'display_name': displayName,
            'location_name': locationName,
            'org_id': organization.id,
            'sortOrder': sortOrder,
            'columns': columns ? columns.sort((a, b) => a.order - b.order).map(c => c.column) : undefined
        };

        await axios.post('study', postData).then(result => {
            setLoading(false);

            if (result.data && result.data.response && result.data.response.name) {
                reload();
                _toggle();

                appCtx.setAlert({ type: 'success', message: study ? 'Updated Study' : 'Created new study' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to create study' });
            }
        }).catch(e => {
            setLoading(false);
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to create study' });
        });
    }

    return (
        <>
            <Dialog open={open} onClose={_toggle} maxWidth="md" fullWidth>
                <DialogTitle>{study?.name ?? 'New Study'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4} direction="column">
                            {error && error !== '' && (
                                <Grid item justify="center">
                                    <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField required label="Study Name" fullWidth value={studyName} onChange={(e) => setStudyName(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label="Local CSV Path" placeholder="Ex: /var/www/html/dash-api/public/<your study>.csv" fullWidth value={csvPath} onChange={(e) => setCsvPath(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField label="Study Order" placeholder="2 (lower means it will appear higher in the list)" fullWidth value={sortOrder} onChange={(e) => setSortOrder(Number(e.target.value))} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField required label="Google Sheet ID" fullWidth value={csvId} onChange={(e) => setCsvId(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField required label="Sheet Name and Starting Column" placeholder="ex: csv!A" fullWidth value={rangeStart} onChange={(e) => setRangeStart(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField required label="Last Column" placeholder="ex: O" fullWidth value={rangeEnd} onChange={(e) => setRangeEnd(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField required label="Display Name" placeholder="Display Name" fullWidth value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField required label="Location Name" placeholder="Location Name" fullWidth value={locationName} onChange={(e) => setLocationName(e.target.value)} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Paper style={{ padding: 10 }}>
                                            <Grid container justify="space-between" alignItems="center">
                                                <Grid item>
                                                    <Button onClick={toggleSecondarySheetsOpen}>
                                                        <ExpandMoreIcon 
                                                            className={clsx(classes.expand, {
                                                                [classes.expandOpen]: secondarySheetsOpen,
                                                            })} 
                                                        />
                                                        <Typography>Secondary Sheets</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Collapse in={secondarySheetsOpen} timeout="auto" unmountOnExit>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <TextField label="Question Sheet ID" fullWidth value={questionsCsvId} onChange={(e) => setQuestionsCsvId(e.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField label="Question Range Start" placeholder="ex: Array!A" fullWidth value={questionsRangeStart} onChange={(e) => setQuestionsRangeStart(e.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField label="Question Range End" placeholder="ex: E" fullWidth value={questionsRangeEnd} onChange={(e) => setQuestionsRangeEnd(e.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="Topics Range Start" fullWidth value={topicsRangeStart} onChange={(e) => setTopicsRangeEnd(e.target.value)} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField label="Topics Range End" fullWidth value={topicsRangeEnd} onChange={(e) => setTopicsRangeEnd(e.target.value)} />
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </Paper>
                                    </Grid>                                    
                                </Grid>
                            </Grid>
                            
                            <Grid item>
                                <Paper style={{ padding: 10 }}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Button onClick={toggleFilters}>
                                                <ExpandMoreIcon 
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: filtersOpen,
                                                    })} 
                                                />
                                                <Typography>Filters</Typography>
                                            </Button>
                                        </Grid>
                                        {filtersOpen && (
                                            <Grid item>
                                                <Tooltip title="Add Filter">
                                                    <IconButton 
                                                        onClick={() => {
                                                            setSelectedStudyFilter(undefined);
                                                            toggleStudyFilter();
                                                        }}
                                                    >
                                                        <AddCircle />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Collapse in={filtersOpen} timeout="auto" unmountOnExit>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Column Name</TableCell>
                                                            <TableCell>Display Name</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {study?.filters && study?.filters.map(filter => (
                                                            <TableRow 
                                                                style={{ cursor: 'pointer' }}
                                                                key={`study_filter_${filter.id}`}
                                                            >
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudyFilter(filter);
                                                                        toggleStudyFilter();
                                                                    }}
                                                                >
                                                                    {filter.columnName}
                                                                </TableCell>
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudyFilter(filter);
                                                                        toggleStudyFilter();
                                                                    }}
                                                                >
                                                                    {filter.displayName}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <IconButton onClick={() => handleDeleteFilter(filter)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Paper>
                            </Grid>

                            {study && <Grid item>
                                <Paper style={{ padding: 10 }}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Button onClick={toggleColumnsOpen}>
                                                <ExpandMoreIcon 
                                                    className={clsx(classes.expand, {
                                                        [classes.expandOpen]: columnsOpen,
                                                    })} 
                                                />
                                                <Typography>Columns</Typography>
                                            </Button>
                                        </Grid>
                                        {columnsOpen && (
                                            <Grid item>
                                                <Tooltip title="Add Column">
                                                    <IconButton 
                                                        onClick={() => {
                                                            setSelectedStudyColumn(undefined);
                                                            toggleColumnDialog();
                                                        }}
                                                    >
                                                        <AddCircle />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Collapse in={columnsOpen} timeout="auto" unmountOnExit>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Column Order</TableCell>
                                                            <TableCell>Column Name</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {columns && columns.sort((a, b) => a.order - b.order).map(column => (
                                                            <TableRow 
                                                                style={{ cursor: 'pointer' }}
                                                                key={`study_column_${column.id}`}
                                                            >
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudyColumn(column);
                                                                        toggleColumnDialog();
                                                                    }}
                                                                >
                                                                    {column.order}
                                                                </TableCell>
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudyColumn(column);
                                                                        toggleColumnDialog();
                                                                    }}
                                                                >
                                                                    {column.column}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <IconButton onClick={() => handleDeleteColumn(column)}>
                                                                        <Delete />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Paper>
                            </Grid>}
                            
                            <Grid item>
                                <Grid container spacing={2} direction="row">
                                    <Grid item>
                                        <Button onClick={handleSubmit} variant="contained" color="primary">
                                            {loading ? <CircularProgress size={24} color="secondary" /> : 'Save'}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={deleteStudy} variant="contained">Delete</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={_toggle} variant="text" color="secondary">Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={deleteStudyDialog} onClose={deleteStudy} maxWidth="sm">
                <DialogTitle>Delete Study</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this study?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={deleteStudy}>No</Button>
                    <Button color="primary" onClick={_deleteStudy}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteFilterDialog} onClose={toggleDeleteFilterDialog} maxWidth="sm">
                <DialogTitle>Delete Filter</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this filter?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={toggleDeleteFilterDialog}>No</Button>
                    <Button color="primary" onClick={_deleteFilter}>Yes</Button>
                </DialogActions>
            </Dialog>

            <StudyFilterDetail open={studyFilterOpen} study={study} toggle={toggleStudyFilter} filter={selectedStudyFilter} reload={reload} setError={setError} />
            <StudyCSVColumnDialog open={columnDialogOpen} study={study} toggle={toggleColumnDialog} column={selectedStudyColumn} setColumnName={setColumnName} />
        </>
    );
};
export default StudyDetail;