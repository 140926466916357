import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Paper,
  Toolbar,
  Box,
  Typography,
  Checkbox,
  makeStyles,
  ListItem,
  ListItemText,
  Button
} from '@material-ui/core';
import {TablePagination} from  '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import { useHistory } from 'react-router';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useParams, useLocation } from "react-router-dom";
import {
  FileCopy as CopyIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import {useSearchContext} from '../../context/SearchContext'

const useStyles = makeStyles((theme) => ({
  root: {},
  itemContainer: {
    backgroundColor: theme.palette.background.default
  },
  title: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main
    }
  },
  image: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
   tableList: {
    borderBottom: "none",
    border: "none",
  },
  item: {
    '&:hover': {
      background: theme.palette.background.default
    },
    '&:active': {
      background: theme.palette.background.default
    }
  },
  sortButton: {
    textTransform: 'none',
    letterSpacing: 0,
    marginRight: theme.spacing(2)
  },
  cardLink: {
    '&:hover': {
      textDecoration: 'none',
    }
  },
  cardItem: {
    transform: 'scale(0.95)',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
    }
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.default
    },
  }
}));


const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'emailFlowStarted',
    numeric: false,
    disablePadding: true,
    label: 'Email Flow Started',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'npo',
    numeric: false,
    disablePadding: false,
    label: 'NPO',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Location Name',
  },
  {
    id: 'hostSelectedDate',
    numeric: false,
    disablePadding: false,
    label: 'Host Selected Date',
  },
  {
    id: 'winnerSelectedDate',
    numeric: false,
    disablePadding: false,
    label: 'Winner Selected Date',
  },
  {
    id: 'hostName',
    numeric: false,
    disablePadding: false,
    label: 'Host Name',
  },
  {
    id: 'hostEmail',
    numeric: false,
    disablePadding: false,
    label: 'Host Email',
  },
  {
    id: 'hostPhone',
    numeric: false,
    disablePadding: false,
    label: 'Host Phone',
  },
  {
    id: 'winnerName',
    numeric: false,
    disablePadding: false,
    label: 'Winner Name',
  },
  {
    id: 'winnerEmail',
    numeric: false,
    disablePadding: false,
    label: 'Winner Email',
  },
  {
    id: 'winnerPhone',
    numeric: true,
    disablePadding: false,
    label: 'Winner Phone',
  },
  {
    id: 'hash',
    numeric: false,
    disablePadding: false,
    label: 'Hash',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property) => (eventType) => {
      onRequestSort(property);
    };


  const excludeHeadings = (heading) => {
    const excludedTypes = ['hash', 'winnerPhone', 'winnerEmail', 'hostEmail', 'hostPhone', 'hostSelectedDate', 'winnerSelectedDate']
    return excludedTypes.includes(heading)
  }

  return (
    <TableHead>
      <TableRow style={{backgroundColor: '#F6F7FF'}}>
        <TableCell padding="checkbox">
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell?.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={'left'}
            sortDirection={orderBy === headCell?.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : 'asc'}
              onClick={createSortHandler(headCell?.id)}
              disabled={excludeHeadings(headCell?.id)}
            >
              {headCell.label}
              {orderBy === headCell?.id ? (
                <Box component="span" >
                  {/* &nbsp;{order === 'desc' ? 'desc' : 'asc'}&nbsp; */}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}        
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

const Paginated = ({dataRows}) => {

  const [order, setOrder] = React.useState('desc');
  const [rows, setRows] = React.useState(dataRows);
  const [orderBy, setOrderBy] = React.useState('creationDate');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const classes = useStyles();
  const [searched, setSearched] = React.useState('');
  const classes = useStyles();
  const history = useHistory();
  const [copied, setCopied] = React.useState();
  // const { search } = useLocation();
  // const query = React.useMemo(() => new URLSearchParams(search), [search]);
  // const index = query.get('index')
  var currentUrl = window.location.pathname
  const search = useSearchContext()

  React.useEffect(() => {
    setPage(search.pageNumber)
    setRowsPerPage(search.resultsNumber)
    setSearched(search.searchTerm)
    if (search.searchTerm !== '') {
      requestSearch(search.searchTerm, false)
    } else if (rows.length <= 0) {
      setRows(dataRows)
    }
  }, [search.pageNumber, search.resultsNumber, search.searchTerm, dataRows])

  // React.useEffect(() => {
  //   if (!rows.length > 0) {
  //     setRows(dataRows)
  //   }
  // }, [dataRows])

  const handleRequestSort = (
    property,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    search.updatePageNumber(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    search.updateResultsNumber(parseInt(event.target.value, 10))
    // setPage(0);
    search.updatePageNumber(0)
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const isSelected = (name: string) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  let emptyRows = 0;
  if(rows){
    emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  }

  const requestSearch = (searchedVal, updatePage) => {
    const filteredRows = dataRows?.filter((row) => {
      const name = row.name.toLowerCase() ?? '';
      const rowId = row?.id?.toString() ?? '';
      const hash = row?.hash.toLocaleLowerCase() ?? '';
      const location = row?.location?.name?.toLocaleLowerCase() ?? '';
      const hostName = row.hosts[0]?.name?.toLocaleLowerCase() ?? '';
      const hostEmail = row.hosts[0]?.email?.toLocaleLowerCase() ?? '';
      const hostPhone = row.hosts[0]?.phone?.toLocaleLowerCase() ?? '';
      const winnerName = row.winners[0]?.name?.toLocaleLowerCase() ?? '';
      const winnerEmail = row.winners[0]?.email?.toLocaleLowerCase() ?? '';
      const winnerPhone = row.winners[0]?.phone?.toLocaleLowerCase() ?? '';
      let searchableString = name + rowId + hostName + hostEmail + hostPhone + winnerName + winnerEmail + winnerPhone + location;
      if (hash.includes(searchedVal.toLowerCase()) ) {
        searchableString = hash
      }
      return searchableString.includes(searchedVal.toLowerCase());
    });
    
    // setPage(0)
    if (updatePage) {
      search.updatePageNumber(0)
    }
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    // setPage(0)
    search.updatePageNumber(0)
    requestSearch('', true);
    search.updateSearchTerm('')
  };

  const copyText = (text, id) => {
    navigator.clipboard.writeText(text);
    setCopied(id)

    setTimeout(()=> {
      setCopied()
    }, 1000)
  }

  return (
    <Box style={{maxWidth: '100%'}}>
      <Paper >
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <SearchBar
          value={searched}
          onChange={(searchVal) => {
            if (searchVal === '' || searchVal === undefined) {
              cancelSearch()
            } else {
              search.updateSearchTerm(searchVal)
            }
            // setTimeout(() => {
            //   // requestSearch(searchVal)
            // }, 400)
          }}
          onCancelSearch={() => cancelSearch()}
        />
        <TableContainer >
          <Table>
            <EnhancedTableHead
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length ? rows?.length : 0}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {rows ? rows.sort((a, b) => {
                let aValue;
                let bValue;
                if(orderBy === 'id'){
                  aValue = a?.id;
                  bValue = b?.id;
                } else if(orderBy === 'name') {
                  aValue = a?.name;
                  bValue = b?.name;
                } else if(orderBy === 'npo') {
                  aValue = a?.npo?.name;
                  bValue = b?.npo?.name;
                } else if(orderBy === 'location') {
                  aValue = a?.location?.name;
                  bValue = b?.location?.name;
                } else if(orderBy === 'hostName') {
                  aValue = a?.hosts[0]?.name;
                  bValue = b?.hosts[0]?.name;
                } else if(orderBy === 'winnerName') {
                  aValue = a?.winners[0]?.name;
                  bValue = b?.winners[0]?.name;
                } else if(orderBy === 'emailFlowStarted') {
                  aValue = a?.emailFlowStarted;
                  bValue = b?.emailFlowStarted;
                }

                if(order === 'asc'){
                  if(aValue !== undefined && bValue !== undefined){
                    if(orderBy === 'name' || orderBy === 'npo' || orderBy === 'location' || orderBy === 'hostName' || orderBy === 'winnerName'){
                      return aValue.toLowerCase().localeCompare(bValue.toLowerCase())
                    } else if(orderBy === 'subcription') {
                      return (aValue.toString().toLowerCase() < bValue.toString().toLowerCase()) ? -1 : (aValue.toString().toLowerCase() > bValue.toString().toLowerCase()) ? 1 : 0;
                    } else if(orderBy === 'id' || orderBy === 'emailFlowStarted') {
                      if(aValue !== undefined && bValue !== undefined){
                        return aValue - bValue;
                      }
                    }
                  } else {
                    if(aValue === undefined){
                      if(orderBy !== 'creationDate'){
                        aValue = '';
                      } else {
                        aValue = 0
                      } 
                    }
                    if(bValue === undefined){
                      if(orderBy !== 'creationDate'){
                        bValue = '';
                      } else {
                        bValue = 0
                      } 
                    }
                    if(orderBy !== 'creationDate'){
                      return aValue.toLowerCase().localeCompare(bValue.toLowerCase())
                    } else {
                      return aValue - bValue;
                    }
                  }
                } else if(order === 'desc') {
                  if(aValue !== undefined && bValue !== undefined){
                    if(orderBy === 'name' || orderBy === 'npo' || orderBy === 'location' || orderBy === 'hostName' || orderBy === 'winnerName'){
                      return bValue.toLowerCase().localeCompare(aValue.toLowerCase())
                    } else if(orderBy === 'subcription') {
                      return (aValue.toString().toLowerCase() < bValue.toString().toLowerCase()) ? -1 : (aValue.toString().toLowerCase() > bValue.toString().toLowerCase()) ? 1 : 0;
                    } else if (orderBy === 'id' || orderBy === 'emailFlowStarted') {
                      return bValue - aValue;
                    }
                  } else {
                    if(aValue === undefined){
                      if(orderBy !== 'creationDate'){
                        aValue = '';
                      } else {
                        aValue = 0
                      } 
                    }
                    if(bValue === undefined){
                      if(orderBy !== 'creationDate'){
                        bValue = '';
                      } else {
                        bValue = 0
                      } 
                    }
                    if(orderBy !== 'creationDate'){
                      return bValue.toLowerCase().localeCompare(aValue.toLowerCase())
                    } else {
                      return bValue - aValue;
                    }
                  }
                } else {
                  return 0;
                }

              })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow  key={`table_${index}`} className={classes.tableRow}  onClick={() => {
                      history.push(`/app/campaigns/${row?.hash}`)
                    }}>
                        <ListItem ></ListItem>
                        <TableCell className={classes.tableList} style={{paddingTop:'0px'}}>{row?.id} </TableCell>
                        {/* <TableCell align="left" className={classes.tableList}>{row?.isCVC}</TableCell> */}
                        <TableCell align="left" className={classes.tableList}>{row?.emailFlowStarted === 1 ? <CheckIcon /> : ''}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.name}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.npo?.name}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.location?.name}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{!row?.hostSelectedDate ? 'False' : 'True'}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{!row?.winnerSelectedDate ? 'False' : 'True'}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.hosts[0]?.name}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.hosts[0]?.email}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.hosts[0]?.phone}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.winners[0]?.name}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.winners[0]?.email}</TableCell>
                        <TableCell align="left" className={classes.tableList}>{row?.winners[0]?.phone}</TableCell>
                        <TableCell align="left" className={classes.tableList} >
                          <div style={{verticalAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} >
                            {row.hash}
                            <Button onClick={(e) => {e.stopPropagation(); copyText(row.hash, `table_${index}`)}}>
                              <CopyIcon style={{margin: 10}} />
                            </Button>
                          </div>
                          {copied === `table_${index}` ? (
                            <Typography style={{color: 'green'}}>Copied!</Typography>
                          ):null}
                        </TableCell>
                    </TableRow>
                   
                  );
                })
                : null}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, ( rows ? rows?.length : 100)]}
          // component="div"
          count={rows ? rows?.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default Paginated;
