import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress
} from "@material-ui/core";
import { Route, Switch, withRouter } from 'react-router-dom'
import useCampaigns from '../../context/CampaignContext'
import { useHistory } from 'react-router-dom';
import EditCampaign from "./EditCampaign";
import EditLocation from "../Locations/EditLocation";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import { useStyles } from "../../themes/TableStyles";
import CreateCampaign from "./CreateCampaign";

import Paginated from './PaginatedTable';
import {
  Button,
  Typography
} from "@material-ui/core";
import {AddCircle as CircleIcon} from '@material-ui/icons';


const AllCampaigns = () => {
  const { campaigns } = useCampaigns();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const classes = useStyles();


  const requestSearch = (searchedVal) => {
    const filteredRows = campaigns.filter((row) => {
      const rowId = row.id?.toString();
      const rowName = row.name?.toLowerCase();
      const rowNpo = row.npo?.name?.toLowerCase();
      const rowLocation = row.location?.name?.toLowerCase();
      const rowHostName = row.hosts[0]?.name?.toLowerCase();
      const rowHostEmail = row.hosts[0]?.email?.toLowerCase();
      const rowHostPhone = row.hosts[0]?.phone?.toLowerCase();
      const rowWinnerName = row.winners[0]?.name?.toLowerCase();
      const rowWinnerEmail = row.winners[0]?.email?.toLowerCase();
      const rowWinnerPhone = row.winners[0]?.phone?.toLowerCase();
      const rowHash = row.hash?.toLowerCase();
      const searchableString = rowId + rowName + rowLocation + rowNpo + rowHostName + rowHostEmail + rowHostPhone + rowWinnerName + rowWinnerEmail + rowWinnerPhone + rowHash;
      return searchableString.includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    if(campaigns)
    setRows(campaigns)
  }, [campaigns])

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };


  return (
    <>
      <Grid container spacing={4} justify="center" alignItems="center">
        {loading && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
        {!loading && (
          <>
            <Grid container justifyContent="flex-end">
                <Button style={{paddingTop: '20px', paddingBottom: '20px'}} onClick={() => {
                    history.push(`/app/campaigns/create`)
                }}>
                    <Typography>Create Campaign&nbsp;&nbsp;</Typography><CircleIcon />
                </Button>
            </Grid>
            <Paginated dataRows={rows}/>
          </>
        )}
      </Grid>
      <Switch>
        {/* <Route exact={true} path="/app/campaigns/:hash" component={EditCampaign} /> */}
        {/* <Route exact={true} path="/app/campaigns/edit" component={CreateCampaign} />
        <Route exact={false} path="/app/campaigns/edit/:hash" component={EditCampaign} /> */}
      </Switch>
    </>
  );
};
export default AllCampaigns;

