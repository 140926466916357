import React, { useState, useEffect, useMemo } from "react";
import { 
    Grid,
    List,
    ListItem, 
    ListItemText,
    Collapse,
    ListSubheader,
    IconButton,
    Tooltip,
    Box,
    Divider,
    DialogContent,
    DialogContentText,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    CircularProgress,
} from "@material-ui/core";
import {
    AddCircle,
    ExpandLess,
    ExpandMore,
    RemoveCircleOutline
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import axios from "axios";
import AllUsersDialog from './AllUsersDialog';
import UserDialog from './UserDialog';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

const OrganizationListItem = ({loading, org, users, addUser, selectedUser, removeUserFromOrg}) => {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);  
     
    const orgUsers = useMemo(() => {
        return (users ?? []).filter(u => u.organizations && u.organizations.find(o => o.id === org.id));
    }, [users]);
    const tableData = useMemo(() => {
        return (orgUsers ?? []).map(u => [
            // u.id, 
            (
                <Button onClick={() => selectedUser(u)}>{u.id}</Button>
            ),
            (
                <Button onClick={() => selectedUser(u)}>{u.email}</Button>
            ),
            (
                <Button onClick={() => selectedUser(u)}>{u.name}</Button>
            ),
            (
                <IconButton onClick={() => removeUserFromOrg(u, org)}><RemoveCircleOutline /></IconButton>
            )
        ]);
    }, [orgUsers]);  

    return (
        <>
            <ListItem button onClick={() => setOpen(!open)}>
                <ListItemText primary={org.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box style={{ margin: 35 }} color="text.primary">
                    
                    {loading && (
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                    {!loading && (
                        <MUIDataTable
                            title={`${org.name} Users`}
                            data={tableData ?? []}
                            columns={["Id", "Email", "Name", ""]}
                            options={{
                                download: false,
                                print: false,
                                filter: false,
                                search: false,
                                viewColumns: false,
                                selectableRows: 'none',
                                // onRowClick: (rowData, rowMeta) => {
                                //     if (rowData) {
                                //         selectedUser(orgUsers.find(u => u.id === rowData[0]));
                                //     }
                                // },
                                customToolbar: () => {
                                    return (
                                        <React.Fragment>
                                            <Tooltip title="Add User">
                                                <IconButton onClick={() => addUser(org)}>
                                                    <AddCircle />
                                                </IconButton>
                                            </Tooltip>
                                        </React.Fragment>
                                    );
                                }
                            }}
                        />
                    )}
                    
                </Box>
            </Collapse>
            <Divider />
        </>
    );
};

const Users = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [users, setUsers] = useState(undefined);
    const [allUsersDialogOpen, setAllUsersDialogOpen] = useState(false); 
    const [userDialogOpen, setUserDialogOpen] = useState(false); 
    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);

    const fetchData = async () => {
        setLoading(true);

        const result = await axios.get('organizations');
        if (result.data.response) {
            setData(result.data.response);
        } else {
            setData(undefined);
        }

        const userResult = await axios.get('users');
        if (userResult.data.response) {
            setUsers(userResult.data.response);
        } else {
            setUsers(undefined);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleAllUsersDialog = () => {
        setShowAddUserDialog(false);
        setAllUsersDialogOpen(!allUsersDialogOpen);
    };

    const toggleUserDialog = () => {
        setSelectedUser(undefined);
        setShowAddUserDialog(false);
        setUserDialogOpen(!userDialogOpen);
    };

    const toggleAddUserDialog = () => {
        setShowAddUserDialog(!showAddUserDialog);
    }

    const addUser = (org) => {
        setSelectedOrg(org);
        toggleAddUserDialog();
    };

    const clickedUser = (user) => {
        setSelectedUser(user);
        setUserDialogOpen(!userDialogOpen);
    }

    const removeUser = async (user, org) => {
        let userData = user;
        let userOrganizations = userData.organizations ?? [];

        console.log(`user: `, user);
        console.log(`org: `, org);
        userOrganizations = userOrganizations.filter(o => o.organization_id !== org.id);
        userData.organizations = userOrganizations;

        const result = await axios.patch('users', userData);

        if (result.data && result.data.response === true) {
            fetchData();
        } 
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader>Users</ListSubheader>
                        }
                        className={classes.root}
                    >
                        {data && data.map(org => 
                            <OrganizationListItem 
                                key={`organization_users_${org.name}`} 
                                loading={loading}
                                org={org} 
                                users={users} 
                                addUser={addUser} 
                                selectedUser={clickedUser} 
                                removeUserFromOrg={removeUser}
                            />
                        )}
                    </List>
                </Grid>
            </Grid>

            <AllUsersDialog open={allUsersDialogOpen} toggle={toggleAllUsersDialog} reload={fetchData} users={users} org={selectedOrg} />
            <UserDialog open={userDialogOpen} toggle={toggleUserDialog} reload={fetchData} user={selectedUser} org={selectedOrg} orgs={data} />

            <Dialog open={showAddUserDialog} toggle={toggleAddUserDialog}>
                <DialogTitle>Add User</DialogTitle>
                <DialogContent>
                    <DialogContentText>Do you want to add an existing user to this organization?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={toggleUserDialog}>Create New User</Button>
                    <Button color="primary" onClick={toggleAllUsersDialog}>Add Existing User</Button>
                    <Button onClick={toggleAddUserDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default Users;