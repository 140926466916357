import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  // Tabs,
  // Tab,
  Fade,
  TextField as Input
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
// import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { receiveToken } from "../../context/UserContext";

//components
import { Button, Typography } from "../../components/Wrappers";
import Widget from "../../components/Widget";
import config from "../../config";


const getGreeting = () => {
  const d = new Date();
  if (d.getHours() >= 4 && d.getHours() <= 12) {
    return "Good Morning!";
  } else if (d.getHours() >= 13 && d.getHours() <= 16) {
    return "Good Day!";
  } else if (d.getHours() >= 17 && d.getHours() <= 23) {
    return "Good Evening!";
  } else {
    return "Good Night!";
  }
};

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");
    if (token) {
      receiveToken(token, userDispatch);
    }
  });

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  // var [activeTabId, setActiveTabId] = useState(0);
  // var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const login = () => {
    try {
      loginUser(
        userDispatch,
        loginValue,
        passwordValue,
        props.history,
        setIsLoading,
        setError
      )
    } catch (e) {
      console.log(`Error: ${e}`);
    }
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={'/images/m4ad-M.png'} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}></Typography>
      </div>
      <div className={classes.formContainer}>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item>
            <div className={classes.form}>
              <form onSubmit={login}>
                {config.isBackend ? (
                  <Widget
                    disableWidgetMenu
                    inheritHeight
                    style={{ marginTop: 32 }}
                  >
                  </Widget>
                ) : null}
                <Typography variant="h1" className={classes.greeting}>
                  {getGreeting()}
                </Typography>
                <Fade
                  in={error}
                  style={
                    !error ? { display: "none" } : { display: "inline-block" }
                  }
                >
                  <Typography color="secondary" className={classes.errorMessage}>
                    Something is wrong with your login or password :(
                  </Typography>
                </Fade>
                <Input
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.InputUnderline,
                      input: classes.Input
                    }
                  }}
                  value={loginValue}
                  onChange={e => setLoginValue(e.target.value)}
                  margin="normal"
                  placeholder="Email Adress"
                  type="email"
                  fullWidth
                />
                <Input
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.InputUnderline,
                      input: classes.Input
                    }
                  }}
                  value={passwordValue}
                  onChange={e => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Password"
                  type="password"
                  fullWidth
                />
                <div className={classes.formButtons}>
                  {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button
                      disabled={
                        loginValue.length === 0 || passwordValue.length === 0
                      }
                      onClick={login}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      Login
                    </Button>
                  )}
                </div>
              </form>
            </div>

            <Typography color="primary" className={classes.copyright}>
              © {new Date().getFullYear()} Member for a Day. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
