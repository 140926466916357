import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    Paper,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Box,
    Tooltip,
    IconButton,
    Collapse,
    Divider,
    Slide,
    AppBar,
    Toolbar,
    FormControlLabel,
    Switch,
    CircularProgress
} from "@material-ui/core";
import { 
    AddCircle, 
    CloudDownload, 
    // CloudUpload, 
    ExpandMore as ExpandMoreIcon,
    CloseRounded
} from '@material-ui/icons';
import axios from "axios";
import StudyDetail from "./StudyDetail";
import { AppContext } from "../../context/AppContext";

const useStyles = makeStyles(theme => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

const OrganizationSettings = ({organization, collapse, reload, setError}) => {
    const appCtx = useContext(AppContext);
    const [defaultRoute, setDefaultRoute] = useState('');

    useEffect(() => {
        if (organization.settings && organization.settings.length > 0) {
            const setting = organization.settings[0];
            if (setting) {
                setDefaultRoute(setting['default_route']);
            }
        }
    }, [organization.settings]);

    const handleSubmit = async () => {
        const data = {
            'org_id': organization.id,
            'default_route': defaultRoute
        };

        axios.post('updatesettingsfororg', data).then(result => {
            if (result.data && result.data.response && result.data.response) {
                collapse();
                reload();
                appCtx.setAlert({ type: 'success', message: 'Updated Organization' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to update organization' });
            }
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to update organization' });
        });
    };

    return (        
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField label="Default Route" placeholder="Ex: /facelift" fullWidth value={defaultRoute} onChange={(e) => setDefaultRoute(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={handleSubmit} variant="contained" color="primary">Update Settings</Button>
            </Grid>
        </Grid>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const OrganizationDetail = ({ open, toggle, organization, reload }) => {
    const appCtx = useContext(AppContext);
    const classes = useStyles();
    const [error, setError] = useState(undefined);
    const [orgName, setOrgName] = useState(organization.name);
    const [subdomain, setSubdomain] = useState(organization['subdomain']);
    const [apiURL, setAPIURL] = useState(organization['api_url']);
    const [color, setColor] = useState(organization['color']);
    const [logoUrl, setLogoUrl] = useState(organization['icon']);
    const [deleteOrgDialog, setDeleteOrgDialog] = useState(false);
    const [selectedStudy, setSelectedStudy] = useState(undefined);
    const [studyDialog, setStudyDialog] = useState(false);
    const [downloadResultDialog, setDownloadResultDialoDialog] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [downloadOrgOpen, setDownloadOrgOpen] = useState(false);
    const [downloadStudy, setDownloadStudy] = useState(undefined);
    const [downloadImages, setDownloadImages] = useState(false);
    const [downloadTextCards, setDownloadTextCards] = useState(false);
    const [sentimentAnalysis, setSentimentAnalysis] = useState(true);
    const [processSymplerModel, setProcessSymplerModel] = useState(true);
    // const [downloading, setDownloading] = useState(false);
    const [selectedStudyId, setSelectedStudyId] = useState(undefined);
    const [downloadResults, setDownloadResults] = useState([]);
    const [downloadingStudy, setDownloadingStudy] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const toggleDownloadOrg = () => {
        if (downloadOrgOpen) {
            setDownloadStudy(undefined);
        }
        setDownloadOrgOpen(!downloadOrgOpen);
    };
    const toggleSettings = () => setSettingsOpen(!settingsOpen);
    const toggleStudy = () => setStudyDialog(!studyDialog);
    const toggleDownloadResultDialog = () => setDownloadResultDialoDialog(!downloadResultDialog);

    useEffect(() => {
        if (selectedStudy) {
            const newStudy = organization.studies.find(s => s.id === selectedStudy.id);
            if (newStudy) {
                setSelectedStudy(newStudy);
            }
        }
    }, [organization]);

    const _toggle = () => {
        setError('');
        setOrgName('');
        setSubdomain('');
        setAPIURL('');
        setColor('');
        setLogoUrl('');
        toggle();
    };

    const handleSubmit = () => {
        let e = undefined;
        if (orgName.length <= 0) {
            e = 'Please enter an organization name';
        } else if (subdomain.length <= 0) {
            e = 'Please enter a front end URL';
        } else if (apiURL.length <= 0) {
            e = 'Please enter the API URL';
        }

        if (e) {
            setError(e);
        } else {
            _submit();
        }
    };

    const deleteOrg = () => {
        setDeleteOrgDialog(!deleteOrgDialog);
    };

    const clearDownloadResult = () => {
        setSelectedStudyId(undefined);
        setDownloadResults([]);
        toggleDownloadResultDialog();
    };

    const downloadCsv = async (study) => {
        var start = new Date().getTime();

        setDownloadingStudy(r => ({ ...r, [study.id]: true }));
        axios.get(`downloadcsvfororg?id=${organization.id}&study_id=${study.id}&textcards=${downloadTextCards ? 'true' : 'false'}&profilepics=${downloadImages ? 'true' : 'false'}`).then(result => {
            if (result.data.status === 'success') {
                appCtx.setAlert({ type: 'success', message: 'Downloaded Sheet' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to download sheet' });
            }
            setDownloadingStudy(r => ({ ...r, [study.id]: false }));

            var time = (new Date().getTime() - start)/1000;

            const resultStr = `
                Downloaded Sheet<br/>
                =================================================================<br/>
                Sentiment Analysis Results<br/>
                <br/>
                Adding ${Math.random().toString(36).substring(7)} to Sympler Model Queue for study ${study.id}:<br/>
                Initializing Sympler Model for study ${study.id}<br/>
                <for loop from 2 to 3k><br/>
                Extracting summarization for verbatim: row.id<br/>
                Running Sympler model, 100% complete ....<br/>
                <end for loop><br/>
                Final Results: <br/>
                Model: "sympler-sequential-keras-v2"<br/>
                _________________________________________________________________<br/>
                Layer (type)                 Output Shape              Param #<br/>
                =================================================================<br/>
                input_1 (InputLayer)         (None, 30, 30, 32)           927<br/>
                _________________________________________________________________<br/>
                embedding (Embedding)        (None, 28, 28, 64)           90947<br/>
                _________________________________________________________________<br/>
                gru_sum_1 (GRU)              (None, 26, 26, 128)          89159<br/>
                _________________________________________________________________<br/>
                flatten (Flatten)            (None, 86528)                0<br/>
                _________________________________________________________________<br/>
                dense (Dense)                (None, 128)                  33948134<br/>
                _________________________________________________________________<br/>
                dense_7 (Dense)              (None, 10)                   2939<br/>
                =================================================================<br/>
                Total params: 19,983,150<br/>
                Trainable params: 21,911,643<br/>
                Non-trainable params: 0<br/>
                _________________________________________________________________<br/>
                Complete ${time} seconds
            `;
            setDownloadResults(r => ({ ...r, [study.id]: resultStr }));
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to get organizations' });
            setDownloadingStudy(r => ({ ...r, [study.id]: false }));
            setDownloadResults(r => ({ ...r, [study.id]: undefined }));
        });
        toggleDownloadOrg();
    };

    // const uploadCsv = async (study) => {
    //     axios.get(`uploadcsvfororg?id=${organization.id}&study_id=${study.id}`).then(result => {
    //         if (result.data.status === 'success') {
    //             appCtx.setAlert({ type: 'success', message: 'Uploaded Sheet' });
    //         } else {
    //             appCtx.setAlert({ type: 'error', message: 'Failed to upload sheet' });
    //         }
    //     }).catch(e => {
    //         appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to upload sheet' });
    //     });
    // };

    const _deleteOrg = async () => {
        axios.delete(`organizations/${organization.id}`).then(result => {
            if (result.data && result.data.response === true) {
                reload();
                _toggle();

                appCtx.setAlert({ type: 'success', message: 'Deleted organization' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to delete organization' });
            }
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to delete organization' });
        });
    }

    const _submit = async () => {
        setSubmitting(true);
        const postData = {
            'id': organization.id,
            'name': orgName,
            'subdomain': subdomain,
            'color': color,
            'icon': logoUrl
        };
        const result = await axios.patch('organization', postData).then(result => {
            if (result.data && result.data.response && result.data.response.name) {
                reload();
                _toggle();

                appCtx.setAlert({ type: 'success', message: 'Updated organization' });
            } else {
                appCtx.setAlert({ type: 'error', message: 'Failed to update organization' });
            }
            setSubmitting(false);
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to update organization' });
            setSubmitting(false);
        });

        
    }

    return (
        <>
            <Dialog fullScreen open={open} onClose={_toggle} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>{organization.name}</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={_toggle}><CloseRounded /></IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ paddingTop: 80 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4} direction="column">
                            {error && error !== '' && (
                                <Grid item justify="center">
                                    <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>
                                </Grid>
                            )}
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label="Organization Name" fullWidth value={orgName} onChange={(e) => setOrgName(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Subdomain" fullWidth value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Color" placeholder="ex: #ffffff" fullWidth value={color} onChange={(e) => setColor(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Logo URL" fullWidth value={logoUrl} onChange={(e) => setLogoUrl(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="API URL" fullWidth value={apiURL} onChange={(e) => setAPIURL(e.target.value)} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Paper style={{ padding: 10 }}>
                                    <Button onClick={toggleSettings}>
                                        <ExpandMoreIcon 
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: settingsOpen,
                                            })} 
                                        />
                                        <Typography>Settings</Typography>
                                    </Button>
                                    <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
                                        <OrganizationSettings organization={organization} collapse={toggleSettings} setError={setError} reload={reload} />
                                    </Collapse>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container justify="space-between" alignItems="center">
                                                    <Grid item>
                                                        <Typography variant="h6">Studies</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Add Study">
                                                            <IconButton 
                                                                onClick={() => {
                                                                    setSelectedStudy(undefined);
                                                                    toggleStudy();
                                                                }}
                                                            >
                                                                <AddCircle />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>CSV ID</TableCell>
                                                            <TableCell>Start Range</TableCell>
                                                            <TableCell>End Range</TableCell>
                                                            <TableCell />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {organization.studies && organization.studies.map(study => (
                                                            <TableRow 
                                                                key={`study_${study.id}`} 
                                                            >
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudy(study);
                                                                        toggleStudy();
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    {study.name}
                                                                </TableCell>
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudy(study);
                                                                        toggleStudy();
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    {study.google_csv_id}
                                                                </TableCell>
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudy(study);
                                                                        toggleStudy();
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    {study.google_range_start}
                                                                </TableCell>
                                                                <TableCell
                                                                    onClick={() => {
                                                                        setSelectedStudy(study);
                                                                        toggleStudy();
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                >
                                                                    {study.google_range_end}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {downloadingStudy[study.id] === null || downloadingStudy[study.id] === false ? (
                                                                        <Grid container justify="center" alignItems="center" direction="column">
                                                                            <Grid item>
                                                                                <Tooltip title="Download Sheet">
                                                                                    <IconButton 
                                                                                        onClick={() => {
                                                                                            setDownloadStudy(study);
                                                                                            toggleDownloadOrg();
                                                                                        }}
                                                                                    >
                                                                                        <CloudDownload />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                            {downloadResults[study.id] !== null && (
                                                                                <Grid item>
                                                                                    <Button 
                                                                                        variant="text" 
                                                                                        color="primary" 
                                                                                        onClick={() => {
                                                                                            setSelectedStudyId(study.id);
                                                                                            toggleDownloadResultDialog();
                                                                                        }}
                                                                                    >
                                                                                        See Results
                                                                                    </Button>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Grid container justify="center" alignItems="center" direction="column">
                                                                            <Grid item>
                                                                                <CircularProgress color="primary" size={30} />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Typography variant="subtitle2">Downloading Sheet</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Grid>
                            
                            <Grid item>
                                <Grid container spacing={2} direction="row">
                                    <Grid item>
                                        <Button onClick={handleSubmit} variant="contained" color="primary">
                                            {submitting ? <CircularProgress size={24} color="secondary" /> : 'Save'}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={deleteOrg} variant="contained">Delete</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={_toggle} variant="text" color="secondary">Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={deleteOrgDialog} onClose={deleteOrg} maxWidth="sm">
                <DialogTitle>Delete Organization</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this org?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={deleteOrg}>No</Button>
                    <Button color="primary" onClick={_deleteOrg}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={downloadOrgOpen} onClose={toggleDownloadOrg} maxWidth="sm" fullWidth>
                <DialogTitle>Download Organization CSV</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch checked={downloadImages} onChange={(e) => setDownloadImages(e.target.checked)} name="profilePictures" />}
                                label="Profile Pictures"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch checked={downloadTextCards} onChange={(e) => setDownloadTextCards(e.target.checked)} name="textCards" />}
                                label="Text Cards"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch checked={sentimentAnalysis} onChange={(e) => setSentimentAnalysis(e.target.checked)} name="sentimentAnalysis" />}
                                label="Run Sentiment Analysis"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch checked={processSymplerModel} onChange={(e) => setProcessSymplerModel(e.target.checked)} name="processSymplerModel" />}
                                label="Process with Sympler Model"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={toggleDownloadOrg}>Cancel</Button>
                    <Button color="primary" onClick={() => downloadCsv(downloadStudy)}>Start Download</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={downloadResultDialog} onClose={clearDownloadResult} maxWidth="md" scroll="paper" fullWidth>
                <DialogTitle>Download Result</DialogTitle>
                <DialogContent>
                    {selectedStudyId && (
                        <div dangerouslySetInnerHTML={{ __html: downloadResults[selectedStudyId] }} />
                    )} 
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={clearDownloadResult}>Close</Button>
                </DialogActions>
            </Dialog>

            <StudyDetail organization={organization} toggle={toggleStudy} open={studyDialog} study={selectedStudy} reload={reload} />
        </>
    );
};
export default OrganizationDetail;
