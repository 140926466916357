import React, { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {  
    CircularProgress,
    TextField,
    Paper,
    InputAdornment,
    MenuItem,
    Typography,
    MenuList,
    IconButton
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';

export const PlacesTextField = ({ name, label, error, selectedLocation }) => {
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState(undefined);

    const handleSelect = (address, placeId, suggestion) => {
        geocodeByAddress(address)
            .then(async (results) => {
                const result = results[0];
                const latLong = await getLatLng(result);
                const name = suggestion.formattedSuggestion.mainText;
                let timezone = '';
                const defaultConfigAxios = axios.create()
                const timezoneResult = await defaultConfigAxios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${latLong.lat},${latLong.lng}&timestamp=1458000000&key=AIzaSyAsqe9jWU2wbtW2OC6PWoszdkfO6lHCP6I`);
                if (timezoneResult?.data?.timeZoneId) {
                    timezone = timezoneResult.data.timeZoneId;
                }

                const location = { address: result.formatted_address, name: name, lat: latLong.lat, long: latLong.lng, timezone };
                setLocation(location);
                selectedLocation(location);
            })
          .catch(error => console.error('Failed to geocode address', error));
    };

    const removeLocation = () => {
        setLocation(undefined);
        selectedLocation(undefined);
        setAddress('');
    }

    return (
        <PlacesAutocomplete
            value={location ? location.name : address}
            onChange={setAddress}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name={name}
                        label={label}
                        {...getInputProps({})}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                {loading && (<CircularProgress color="primary" />)}
                                {location && (<IconButton onClick={removeLocation}><HighlightOffIcon /></IconButton>)}
                              </InputAdornment>
                            ),
                        }}
                        error={Boolean(error)}
                        helperText={error}
                    />

                    {suggestions && suggestions.length > 0 && (
                        <Paper>
                            <MenuList>
                                {suggestions.map((suggestion, i) => {
                                    return (
                                        <MenuItem 
                                            key={`menuitem${i}-${suggestion.description}`} 
                                            {...getSuggestionItemProps(suggestion, {})}
                                        >
                                            <Typography variant="inherit">{suggestion.description}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </MenuList>
                        </Paper>       
                    )}           
                </div>
            )}
        </PlacesAutocomplete>
    );
};