import React, { useState, useEffect } from "react";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Drawer, IconButton, List, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  Home as HomeIcon,
  // FilterNone as UIElementsIcon,
  // BorderAll as TableIcon,
  // QuestionAnswer as SupportIcon,
  // LibraryBooks as LibraryIcon,
  // HelpOutline as FAQIcon,
  // BarChart as ChartIcon,
  // Map as MapIcon,
  // Apps as CoreIcon,
  // Description as DescriptionIcon,
  // ShoppingCart as ShoppingCartIcon,
  // StarBorder as ExtraIcon,
  // Chat as ChatIcon,
  // Add as AddSectionIcon,
  // FolderOpen as FolderIcon,
  // Description as DocumentationIcon,
  // Person as UserIcon,
  Business as BusinessIcon,
  LocationOn as LocationIcon,
  RecordVoiceOver as RecordIcon, 
  DateRange as CalendarIcon,
  SupervisedUserCircleOutlined as SupervisedUserIcon,
  Dvr as DvrIcon,
  ChatRounded
} from '@material-ui/icons'

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from "../../context/LayoutContext";


function Sidebar({ location, structure }) {
  var classes = useStyles();
  var theme = useTheme();
  const localStorageData = localStorage.getItem('Member for a Day');
  const USER = JSON.parse(localStorageData).user;

  const toggleDrawer = value => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: !isPermanent ? !isSidebarOpened : isSidebarOpened,
        [classes.drawerClose]: !isPermanent ? isSidebarOpened : !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: !isPermanent
            ? !isSidebarOpened
            : isSidebarOpened,
          [classes.drawerClose]: !isPermanent
            ? isSidebarOpened
            : !isSidebarOpened
        })
      }}
      open={!isPermanent ? !isSidebarOpened : isSidebarOpened}
      onClose={toggleDrawer(true)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      </div>
      <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
        <Grid item>
          <List
            className={classes.sidebarList}
            classes={{ padding: classes.padding }}
          >
            {/* {structure.map(link => (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
                {...link}
                toggleDrawer={toggleDrawer(true)}
              />
            ))} */}

            <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={0}
              label={'Campaigns'} 
              link={'/app/campaigns'}
              icon={<RecordIcon />}
            />
            {/* <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={1}
              label={'Locations'} 
              link={'/app/locations'}
              icon={<LocationIcon />}
            /> */}
            <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={1}
              label={'Calendar'} 
              link={'/app/calendar'}
              icon={<CalendarIcon />}
            />
              <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={2}
              label={USER.role === 'ADMIN' ? 'NPOs' : 'My Organization'} 
              link={'/app/npos'}
              icon={<BusinessIcon />}
            />
            {/* <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={2}
              label={'Winners'} 
              link={'/app/users'}
              icon={<SupervisedUserIcon />}
            />
            <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={2}
              label={'Chat'} 
              link={'/app/chat'}
              icon={<ChatRounded />}
            /> */}
          </List>
        </Grid>
        <Grid item justify="flex-end" alignItems="flex-end">
          <List
            className={classes.sidebarList}
            classes={{ padding: classes.padding }}
          >
            {/* <SidebarLink
              location={location}
              isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
              toggleDrawer={toggleDrawer(true)}
              id={3}
              label={'Server Logs'} 
              link={'/app/logs'}
              icon={<DvrIcon />}
            />   */}
          </List>
        </Grid>
      </Grid>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
