import React, {createContext, useContext, useState} from 'react'


export const SearchContext = createContext({
    searchTerm: '',
    pageNumber: 0,
    resultsNumber: 0,
    updateSearchTerm: () => {},
    updatePageNumber: () => {},
    updateResultsNumber: () => {},
})

export const SearchContextProvider = ({children}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [pageNumber, setPageNumber] = useState(0)
    const [resultsNumber, setResultsNumber] = useState(5)

    const updateSearchTerm = (term) => {
        setSearchTerm(term)
    }

    const updatePageNumber = (number) => {
        setPageNumber(number)
    }

    const updateResultsNumber = (number) => {
        setResultsNumber(number)
    }

    return (
      <SearchContext.Provider
        value={{
            searchTerm,
            pageNumber,
            resultsNumber,
            updateSearchTerm,
            updatePageNumber,
            updateResultsNumber,
        }}
      >
        {children}
      </SearchContext.Provider>
    )
}

export const useSearchContext = () =>
  useContext(SearchContext)
