import React, {useState, useEffect} from "react";
import {
    Grid,
    CircularProgress
} from "@material-ui/core";
import {Route, Switch, withRouter} from 'react-router-dom'
import useCampaigns from '../../context/CampaignContext'
import {useHistory} from 'react-router-dom';
// import EditLocation from "../Locations/EditLocation";

import EditNpo from "./EditNpo";

import MUIDataTable from "mui-datatables";

import {useStyles} from "../../themes/TableStyles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import axios from 'axios';
import {API_URL} from '../../constants';
import {
    Button
} from "@material-ui/core";
import {AddCircle as CircleIcon} from '@material-ui/icons';
import CreateNewNpo from './CreateNewNpo';
import {Typography} from "@material-ui/core";

// test


const AllNPOs = () => {
    const history = useHistory();
    const {campaigns} = useCampaigns();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [searched, setSearched] = useState("");
    const [npos, setNpos] = useState([]);
    const [rows, setRows] = useState();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;


    useEffect(() => {
        if (USER.role == 'ADMIN') {
            // if (campaigns) {
            //     const allNpos = campaigns.filter(f => f.npo != null).map(c => c.npo);
            //     setNpos(allNpos.filter((npo, index) => {
            //         return allNpos.findIndex((childNpo) => {
            //             return childNpo.id == npo.id
            //         }) == index;
            //     }));
            // }    
            const getNPOs = async () => {
                var results = await axios.get(`${API_URL}/npo`);
                setNpos(results.data.response)
            }
            getNPOs();
        }
        else if (USER.role == 'NPO') {
            const allNpos = campaigns.filter(f => f.npo != null).filter(n => n.npo.id == USER.npoId).map(c => c.npo);
            setNpos(allNpos.filter((npo, index) => {
                return allNpos.findIndex((childNpo) => {
                    return childNpo.id == npo.id
                }) == index;
            }));
        }

    }, [campaigns])

    useEffect(() => {
        setRows(npos)
    }, [npos])


    const requestSearch = (searchedVal) => {
        const filteredRows = npos.filter((row) => {
            const rowId = row.id?.toString();
            const rowName = row.name?.toLowerCase();
            const rowCampaign = row.campaignName?.toLowerCase();
            const rowHash = row.hash?.toLowerCase();
            const searchableString = rowId + rowName + rowCampaign + rowHash;
            return searchableString.includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };


    return (
        <>
            <Grid container justifyContent="flex-end">
                <Button style={{paddingTop: '20px', paddingBottom: '20px'}} onClick={() => {
                    history.push(`/app/npos/edit/`)
                }}>
                    <Typography>Create NPO&nbsp;&nbsp;</Typography><CircleIcon />
                </Button>
            </Grid>
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {loading && (
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                )}
                {!loading && (
                    <Grid item xs={12}>
                        <Paper variant='outlined'>
                            {USER.role == 'ADMIN' &&
                                <SearchBar
                                    value={searched}
                                    onChange={(searchVal) => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                />
                            }
                            <TableContainer className={classes.container}>
                                <Table className={classes.table} aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">Campaign Name</TableCell>
                                            <TableCell align="left">Hash</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows?.length > 0 && rows?.map((npo, key) => (
                                            <TableRow className={classes.cell} key={key} onClick={() => {
                                                history.push(`/app/npos/edit/${npo.hash}`)
                                            }}>
                                                <TableCell component="th" scope="row">{npo.id}</TableCell>
                                                <TableCell align="left">{npo.name}</TableCell>
                                                <TableCell align="left">{npo.campaignName}</TableCell>
                                                <TableCell align="left">{npo.hash}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <Switch>
                {/* <Route exact={true} path="/app/npos/edit" component={CreateNewNpo} />
                <Route exact={false} path="/app/npos/edit/:hash" component={CreateNewNpo} /> */}
            </Switch>
        </>
    );
};
export default AllNPOs;
