import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Paper,
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL } from '../../constants';
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import { PlacesTextField }from '../../components/Global/PlacesTextField';



export const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.longitude)

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    )
}
))


function AddLocation({showAddLocation, setShowAddLocation, locationData, setLocationData}) {
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const history = useHistory();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;
    const [locationError, setLocationError] = useState(undefined);
    const [location, setLocation] = useState();
    

    const initialValues = {
        name: locationData?.name || '',
        address: locationData?.address || '',
        latitude: locationData?.lat || '',
        longitude: locationData?.long || '',
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        setLocationData(location)
        setShowAddLocation(false)

        setSubmitting(false);
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Grid
                                container
                                spacing={3}
                                justifyContent={'center'}
                            >
                                <Dialog
                                    fullScreen={false}
                                    open={true}
                                    onClose={() => { setShowAddLocation(false) }}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <Paper>
                                    <DialogTitle id="responsive-dialog-title">
                                        <IconButton
                                            color="inherit"
                                            onClick={() => setShowAddLocation(false)}
                                            className={classNames(
                                                classes.headerMenuButton,
                                                classes.headerMenuButtonCollapse
                                            )}
                                            style={{ marginLeft: '0px' }}
                                        >
                                            <ArrowBackIcon
                                                classes={{
                                                    root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                }}
                                            />
                                        </IconButton>
                                        {"Edit Location"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <PlacesTextField 
                                                name="club" 
                                                label="Name of Golf Course" 
                                                selectedLocation={(l) => {
                                                    setLocation(l);
                                                    setLocationError(undefined);
                                                }} 
                                                error={locationError}
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Name"
                                                name="name"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.name : form.values.name}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Address"
                                                name="address"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.address : form.values.address}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Latitude"
                                                name="latitude"
                                                type="text"
                                                disabled
                                                // onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.lat : form.values.latitude}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Longitude"
                                                name="longitude"
                                                type="text"
                                                disabled
                                                // onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={location ? location.long : form.values.longitude}
                                                variant="outlined"
                                            />
                                        </Box>
                                    
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => { setShowAddLocation(false) }}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={form.isSubmitting || !location}
                                            onClick={() => {
                                                form.submitForm();
                                            }}
                                        >
                                            Ok
                                        </Button>
                                    </DialogActions>
                                    </Paper>
                                </Dialog>
                            </Grid>
                        </form>
                    )
                }
                }
            </Formik >
        </>
    )
}

export default AddLocation;