import React, { useState, useContext } from "react";
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress
} from "@material-ui/core";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
  
export const AddOrganization = ({ open, toggle, reload }) => {
    const appCtx = useContext(AppContext);
    const [error, setError] = useState(undefined);
    const [orgName, setOrgName] = useState('');
    const [color, setColor] = useState('');
    const [icon, setIcon] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [study, setStudy] = useState('');
    const [sheetId, setSheetId] = useState('');
    const [sheetName, setSheetName] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const _submit = async () => {
        setSubmitting(true);

        const postData = {
            'name': orgName,
            'subdomain': subdomain,
            'color': color,
            'icon': icon,
            'study_name': study,
            'sheet_id': sheetId,
            'sheet_name': sheetName
        };
        
        axios.post('organization', postData).then(result => {
            if (result.data && result.data.response && result.data.response.name) {
                reload();
                _toggle();

                appCtx.setAlert({ type: 'success', message: 'Created Organization' });
            } else {
                setError('Oops, something went wrong. Please try again.');

                appCtx.setAlert({ type: 'error', message: 'Failed to create organization' });
            }
            setSubmitting(false);
        }).catch(e => {
            appCtx.setAlert({ type: 'error', message: e?.data?.message ?? 'Failed to get organizations' });
            setSubmitting(false);
        });
    }

    const handleSubmit = (event) => {
        let e = undefined;
        if (orgName.length <= 0) {
            e = 'Please enter an organization name';
        } else if (subdomain.length <= 0) {
            e = 'Please enter a subdomain';
        } else if (color.length <= 0) {
            e = 'Please enter the color';
        } else if (sheetId.length <= 0) {
            e = 'Please enter a google sheet id';
        } else if (sheetName.length <= 0) {
            e = 'Please enter a google sheet name';
        } else if (study.length <= 0) {
            e = 'Please enter the name for your study';
        }

        if (e) {
            event.preventDefault();
            setError(e);
        } else {
            _submit();
        }
    };

    const _toggle = () => {
        setError('');
        setOrgName('');
        setColor('');
        setIcon('');
        setStudy('');
        setSheetName('');
        setSheetId('');
        setSubdomain('');
        toggle();
    };

    return (
        <Dialog open={open} onClose={_toggle} maxWidth="lg" fullWidth>
            <DialogTitle>Add Organization</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={4} direction="column">
                        <Grid item justify="center">
                            <Typography variant="body1" style={{ color: 'red' }}>{error}</Typography>
                        </Grid> 
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label="Organization Name" fullWidth required value={orgName} onChange={(e) => setOrgName(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Subdomain" fullWidth required value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Color" placeholder="Ex: #fafafa" fullWidth required value={color} onChange={(e) => setColor(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Icon URL" fullWidth value={icon} onChange={(e) => setIcon(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Study Name" required fullWidth value={study} onChange={(e) => setStudy(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Sheet ID" required fullWidth value={sheetId} onChange={(e) => setSheetId(e.target.value)} />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField label="Sheet Name" placeholder="Ex: csv" required fullWidth value={sheetName} onChange={(e) => setSheetName(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="row">
                                <Grid item>
                                    <Button onClick={handleSubmit} variant="contained" color="primary">
                                        {submitting ? <CircularProgress size={24} color="secondary" /> : 'Save'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={_toggle} variant="text" color="secondary">Cancel</Button>
                                </Grid>
                            </Grid>                            
                        </Grid>
                    
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
export default AddOrganization;