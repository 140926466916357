import React, {useEffect, useState} from "react";
import {
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  TextField as Input,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from "@material-ui/core";
import {
  KeyboardArrowLeft as LeftArrowIcon,
  KeyboardArrowRight as RightArrowIcon,
  CalendarToday as CalendarIcon
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

import s from "./Calendar.module.scss";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import moment from "moment/moment";
import cn from "classnames";

//components
import Widget from "../../components/Widget";
import Dot from "../../components/Sidebar/components/Dot";
import { Button, Typography } from "../../components/Wrappers";
import useCampaigns from '../../context/CampaignContext';

const Calendar = () => {
  const date = new Date();
  const d = date.getDate();
  const m = date.getMonth();
  const y = date.getFullYear();
  const [event, setEvent] = useState({});
  const [modal, setModal] = useState(false)
  const [modalEvent, setModalEvent] = useState(false);
  const [calendarView, setCalendarView] = useState("dayGridMonth");
  const [currentMoment, setCurrentMoment] = useState(moment().format("MMM YYYY"))
  const [currentDay, setCurrentDay] = useState(moment().format("dddd"))
  const [events, setEvents] = useState([]);
  const calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  const dragOptions = { zIndex: 999, revert: true, revertDuration: 0 };
  const {campaigns, getCampaigns} = useCampaigns();
  const [sortableCampaigns, setSortableCampaigns] = useState()
  const [npo, setNpo] = useState('all')
  let fullCalendar = new FullCalendar();
  const history = useHistory();
  useEffect(() => {
    if (!sortableCampaigns) {
      return
    }

    let events = sortableCampaigns.filter(c => c.winner_dates.length > 0 || c.host_dates.length > 0).map(c => {
      let dates = []
      if (c.winner_dates.length > 0) {
        dates = c.winner_dates.filter(d => {
          if (d.isSelected === 1 || d.isFinalDate === 1) {
            return d
          }
        })
      }
      if (c.host_dates.length > 0) {
        dates = c.host_dates.filter(d => {
          if (d.isSelected === 1 || d.isFinalDate === 1) {
            return d
          }
        })
      }
      if (dates.length > 0) {
        function generateColorHexCodeFromString(str) {
          if (!str || str === undefined || str === '') {
            return "#64bd63"
          }
          var hash = 5381;
          for (var i = 0; i < str.length; i++) {
            hash = (hash * 33) ^ str.charCodeAt(i);
          }
          var color = (hash >>> 0).toString(16);
          color = color.padStart(6, '0');
        
          // Check if color is too light
          var red = parseInt(color.substring(0, 2), 16);
          var green = parseInt(color.substring(2, 4), 16);
          var blue = parseInt(color.substring(4, 6), 16);
          var lightThreshold = 70; // Adjust this threshold as needed
          
          if (red > lightThreshold || green > lightThreshold || blue > lightThreshold) {
            // Color is too light, generate a new color
            if (red > lightThreshold) {
                red = Math.floor(red * (lightThreshold * .01))
            }
            red = red.toString(16).padStart(2, '0')
            if(green > lightThreshold) {
              green = Math.floor(green * (lightThreshold * .01))
            }
            green = green.toString(16).padStart(2, '0')
						if (blue > lightThreshold) {
              blue = Math.floor(blue * (lightThreshold * .01))
            }
            blue = blue.toString(16).padStart(2, '0')
            color = `${red}${blue}${green}`
          }
        
          return '#' + color;
        }    
        const timestamp = dates?.find(d => d?.isFinalDate === 1)?.date;
        const date = new Date(timestamp);
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        let event = {
          title: c.name,
          start: new Date(dates[0]?.date),
          backgroundColor: generateColorHexCodeFromString(c.npo?.hash),
          textColor: "#fff",
          description: "",
          campaign: c,
          timeOfDay: time
        }
        return event
      }
      return {}
    })
    setEvents(events)
  }, [sortableCampaigns])

  useEffect(() => {
    if (campaigns) {
      setSortableCampaigns(campaigns)
    }
  }, [campaigns])

  const filterByNpo = (npo) => {
    setNpo(npo)
    if (npo !== 'all') {
      let sort = campaigns.filter(c => c.npo?.hash === npo)
      setSortableCampaigns(sort)
    }
    else setSortableCampaigns(campaigns)
  }
  
  // useEffect(() => {
  //   new Draggable(this.externalEvents, {
  //     itemSelector: ".external-event"
  //   });
  // },[])

  const drop = info => {
    info.draggedEl.parentNode.removeChild(info.draggedEl);
  };

  const handleChange = e => {
    setEvent({ ...event, title: e.target.value })
  };
  const createEvent = e => {
    fullCalendar.getApi().addEvent(e);
    fullCalendar.getApi().unselect();
    toggleModal();
  };
  const select = ({ start, end, allDay }) => {
    setEvent({
        start,
        end,
        allDay,
        backgroundColor: "#64bd63",
        textColor: "#fff",
        editable: true
      }
    )
    toggleModal();
  };
  const eventClick = e => {
    setEvent(e.event)
    toggleModalEvent();
  };
  const prev = () => {
    fullCalendar.getApi().prev();
  };
  const next = () => {
    fullCalendar.getApi().next();
  };
  const today = () => {
    fullCalendar.getApi().today();
  };
  const changeView = view => {
    fullCalendar.getApi().changeView(view);
    setCalendarView(view)
  };
  const getFormattedDate = date => {
    return moment(date).format("YYYY-MM-DD");
  };
  const toggleModal = () => {
    // No event creation allowed
    // setModal(!modal)
  };
  const toggleModalEvent = () => {
    setModalEvent(!modalEvent)
  };

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Widget disableWidgetMenu>
              <Grid
                container
                item
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <IconButton onClick={prev} aria-label="previous page">
                    <LeftArrowIcon />
                  </IconButton>
                  <IconButton onClick={next} aria-label="next page">
                    <RightArrowIcon />
                  </IconButton>
                  <Button onClick={today}>Today</Button>
                </Grid>
                <Grid item>
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Filter by npo</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={npo}
                        label="Age"
                        onChange={(e) => filterByNpo(e.target.value)}
                        variant="filled"
                      >
                        <MenuItem value={'all'}>All</MenuItem>
                        {campaigns?.filter((element, index, self) => {
                          return index === self.findIndex(obj => obj.npo?.hash === element.npo?.hash);
                        }).map(c => (
                          <MenuItem value={c.npo?.hash}>{c.npo?.name}</MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => changeView("dayGridMonth")}
                    color={
                      calendarView === "dayGridMonth"
                        ? "primary"
                        : "none"
                    }
                    variant={
                      calendarView === "dayGridMonth"
                        ? "contained"
                        : "text"
                    }
                  >
                    Month
                  </Button>
                  <Button
                    onClick={() => changeView("timeGridWeek")}
                    color={
                      calendarView === "timeGridWeek"
                        ? "primary"
                        : "none"
                    }
                    variant={
                      calendarView === "timeGridWeek"
                        ? "contained"
                        : "text"
                    }
                    style={{ marginLeft: 8 }}
                  >
                    Week
                  </Button>
                  <Button
                    onClick={() => changeView("timeGridDay")}
                    color={
                      calendarView === "timeGridDay"
                        ? "primary"
                        : "none"
                    }
                    variant={
                      calendarView === "timeGridDay"
                        ? "contained"
                        : "text"
                    }
                    style={{ marginLeft: 8 }}
                  >
                    Day
                  </Button>
                </Grid>
              </Grid>
              <FullCalendar
                ref={node => {
                  fullCalendar = node;
                }}
                defaultView="dayGridMonth"
                plugins={calendarPlugins}
                select={select}
                eventClick={eventClick}
                drop={drop}
                events={events}
                selectable={true}
                selectHelper={true}
                editable={true}
                droppable={true}
                // {...calendarOptions}
              />
            </Widget>
          </Grid>
        </Grid>

        <Dialog open={modal} onClose={toggleModal} id="news-close-modal">
          <DialogTitle id="news-close-modal-label">
            Create New Event
          </DialogTitle>
          <Box m={3} flexDirection="column">
            <Typography variant="body2" style={{ marginBottom: 16 }}>
              Just enter event name to create a new one
            </Typography>
            <Input
              onChange={handleChange}
              className={s.calendarModalInput}
              value={event.title}
              name="title"
              placeholder="Title"
            />
            <Box mt={2}>
              <Button
                onClick={toggleModal}
                data-dismiss="modal"
                style={{ marginRight: 8 }}
              >
                Close
              </Button>{" "}
              <Button
                color="success"
                variant="contained"
                onClick={createEvent}
                id="news-widget-remove"
              >
                Create
              </Button>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          open={modalEvent}
          onClose={toggleModalEvent}
          id="news-close-modal"
        >
          <DialogTitle id="news-close-modal-label">{event.title}</DialogTitle>
          <Box m={3}>
            <Typography style={{ marginBottom: 16 }}>
              <CalendarIcon style={{ marginRight: 8 }} />
              {getFormattedDate(event.start)}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 16 }}>
              Start Time: {event.extendedProps && event.extendedProps.timeOfDay}
              <br></br>
              <br></br>
              NPO:  {event.extendedProps && event.extendedProps.campaign.npo?.name}
              <br></br>
              NPO Contact:  {event.extendedProps && event.extendedProps.campaign.npo?.contacts[0]?.name} | {event.extendedProps && event.extendedProps.campaign.npo?.contacts[0]?.email}
              <br></br>
              Winner: {event.extendedProps && event.extendedProps.campaign?.winners[0]?.name} | {event.extendedProps && event.extendedProps.campaign?.winners[0]?.email}
              <br></br>
              Host: {event.extendedProps && event.extendedProps.campaign?.hosts[0]?.name} | {event.extendedProps && event.extendedProps.campaign?.hosts[0]?.email}
              <br></br>
              Location: {event.extendedProps && event.extendedProps.campaign.location?.name}
              <br></br>
              <br></br>
              Description: {event.extendedProps && event.extendedProps.campaign?.packageDescription}
              {/* {event.extendedProps && event.extendedProps.description} */}
            </Typography>
            <Grid container justifyContent="space-around">
              <Grid item md={8}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={toggleModalEvent}
                  data-dismiss="modal"
                >
                  OK
                </Button>
              </Grid>
              <Grid item md={4}>
                <Button
                  // style={{position: 'absolute', right: 0, marginRight: '24px'}}
                  style={{width: '100%'}}
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/app/campaigns/${event.extendedProps && event.extendedProps.campaign.hash}`)}
                  data-dismiss="modal"
                >
                  View Campaign
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </>
    );
  }

export default Calendar;
