import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Paper,
    Box,
    Button,
    Card,
    CardContent,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    makeStyles,
    Tooltip,
    InputAdornment,
    IconButton,
    CardMedia,
    Switch,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Slider,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import useStyles from './styles';
import { Route, Switch as RouteSwitch, useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL } from '../../constants';
import styles from '../Locations/styles';
import {
    ArrowBack as ArrowBackIcon,
    Send as SendIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import EditLocation from "../Locations/EditLocation";
import EditHost from '../Host/EditHost';
import EditWinner from'../Winner/EditWinner';
import AddHost from './addHost';
import AddWinner from './addWinner';
import AddLocation from './addLocation';

const customClass = makeStyles((theme) => ({
    red: {
        "& .MuiFilledInput-root": {
            background: "#F47174"
        }
    },
    green: {
        "& .MuiFilledInput-root": {
            background: "#ACD1AF"
        }
    }
}));


export const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.longitude)

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    )
}
))


function CreateCampaign() {
    const { campaigns, getCampaigns } = useCampaigns();
    const [campaign, setCampaign] = useState();
    const [location, setLocation] = useState();
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const fillClass = customClass();
    const history = useHistory();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;
    const [npos, setNpos] = useState();
    const [npo, setNpo] = useState();
    const [includesMeal, setIncludesMeal] = useState(false);
    const [hostASecondRound, setHostASecondRound] = useState(false);
    const [includesCaddies, setIncludesCaddies] = useState(false);
    const [showEmailWarning, setShowEmailWarning] = useState(false);
    const [emailFlowStarted, setEmailFlowStarted] = useState(false);
    const [emailFlowError, setEmailFlowError] = useState(false);
    const [emailFlowErrorMessage, setEmailFlowErrorMessage] = useState('');
    const [isCvc, setIsCvc] = useState(false);
    const [winner, setWinner] = useState();
    const [host, setHost] = useState();
    const [showAddLocation, setShowAddLocation] = useState(false)
    const [showAddhost, setShowAddHost] = useState(false)
    const [showAddWinner, setShowAddWinner] = useState(false)
    const [submitResult, setSubmitResult] = useState();
    const [response, setResponse] = useState();

    const updateBoolean = (numValue, stateFunction) => {
        if (numValue === 1) {
            stateFunction(true);
        } else {
            stateFunction(false);
        }
    }

    useEffect(() => {
        const getNpos = async() => {
            if(npos) {
                return
            }
            try {
                const response = await axios.get(`${API_URL}/npo`)
                setNpos(response.data.response)
            } catch (error) {
                console.error('Error: ', error)
            }
        }
        getNpos()
    })

    const initialValues = {
        id: '',
        flow: 'WINNER_FIRST',
        createdAt: '',
        modified: '',
        name: '',
        numberOfAllowedGuests: 0,
        roundsOfGolf: 0,
        includesMeal: 0,
        hostASecondRound: 0,
        includesCaddies: 0,
        packageDescription: '',
        clubRules: '',
        updatedAt: '',
        locationName: '',
        address: '',
        latitude: '',
        longitude: '',
        npo: '',
        locationId: 0,
        timeZone: '',
        date: '',
        winner: '',
        host: '',
        hostSelectedDate: 'false',
        winnerSelectedDate: 'false',
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        const formValues = {
            flow: 'WINNER_FIRST',

            locationName: location ? location.name : '',
            address: location ? location.address : '',
            latitude: location ? location.latitude : '',
            longitude: location ? location.longitude : '',
            npoId: npo?.id,

            timeZone: '',

            winner: winner ? winner : '',
            host: host ? host :'',
            hostSelectedDate: 'false',
            winnerSelectedDate: 'false',
            location: location ? location : '',

            name: values.name,
            numberOfAllowedGuests: values.numberOfAllowedGuests,
            roundsOfGolf: values.roundsOfGolf,
            includesMeal: includesMeal === true ? 1 : 0,
            hostASecondRound: hostASecondRound === true ? 1 : 0,
            includesCaddies: includesCaddies === true ? 1 : 0,
            packageDescription: values.packageDescription,
            clubRules: values.clubRules,
            rallyUpId: 'campaignCreatedOnAdminDash',
        }

        try {
            let response = await axios.post(`${API_URL}/newcampaign`, formValues)
            setSubmitting(false);
            getCampaigns();
            console.log('asdf', response.data.data)
            if (response.data.response.status === 'failed') {
                setSubmitResult(`Error creating new campaign: ${response.data.data.message}`)
            } else {
                setSubmitResult('Successfully created new campaign')
            }
            setResponse(response)
        } catch (e) {
            setSubmitting(false);
            setSubmitResult(`Error creating new campaign: ${e.data.message}`)
            console.log('Failed to create campaign with error: ', e)
        }

        
        // history.goBack();
    }


    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Paper>
                                <Grid
                                    container
                                    // spacing={3}
                                    justifyContent={'space-between'}
                                    style={{position: 'relative'}}
                                >
                                            <Dialog
                                        fullScreen={false}
                                        open={submitResult && submitResult !== ''}
                                        onClose={() => setSubmitResult()}
                                        aria-labelledby="responsive-dialog-title2"
                                    >
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    {submitResult}
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => {
                                                            setSubmitResult(); 
                                                            if(response) {
                                                                history.push(`/app/campaigns/${response?.data?.response?.hash}`)
                                                            } 
                                                        }}
                                                        color="primary"
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </>
                                    </Dialog>
                                    <Dialog
                                        fullScreen={false}
                                        open={showAddhost}
                                        onClose={() => setShowAddHost(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    // fullWidth
                                    >
                                        <AddHost showAddhost={showAddhost} setShowAddHost={setShowAddHost} host={host} setHost={setHost} />
                                    </Dialog>

                                    <Dialog
                                        fullScreen={false}
                                        open={showAddWinner}
                                        onClose={() => setShowAddWinner(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    // fullWidth
                                    >
                                        <AddWinner showAddWinner={showAddWinner} setShowAddWinner={setShowAddWinner} winnerData={winner} setWinnerData={setWinner} />
                                    </Dialog>

                                    <Dialog
                                        fullScreen={false}
                                        open={showAddLocation}
                                        onClose={() => setShowAddLocation(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    // fullWidth
                                    >
                                        <AddLocation showAddLocation={showAddLocation} setShowAddLocation={setShowAddLocation} locationData={location} setLocationData={setLocation} />
                                    </Dialog>

                                    <Grid item xs={12}>
                                        <DialogTitle id="responsive-dialog-title">
                                            <Grid container spacing={3} >
                                                <Grid item xs={12} md={10}>
                                                    <IconButton
                                                        color="inherit"
                                                        onClick={() => history.goBack()}
                                                        className={classNames(
                                                            classes.headerMenuButton,
                                                            classes.headerMenuButtonCollapse
                                                        )}
                                                        style={{ marginLeft: '0px' }}
                                                    >
                                                        <ArrowBackIcon
                                                            classes={{
                                                                root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                            }}
                                                        />
                                                    </IconButton>{"Create Campaign"}
                                                </Grid>
                                                <Grid item xs={12} md={2} style={{right: 0, position: 'absolute'}}>
                                                    {form.values.host !== '' && form.values.winner !== '' ?
                                                        <>
                                                            {'Start Email Flow'}
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={() => setShowEmailWarning(true)}
                                                                className={classNames(
                                                                    classes.headerMenuButton,
                                                                    classes.headerMenuButtonCollapse
                                                                )}
                                                                style={{ marginLeft: '10px' }}
                                                            >
                                                                <SendIcon
                                                                    classes={{
                                                                        root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </>
                                                        : null}
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                    </Grid>
                                    {/* <DialogContent> */}
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={1} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="ID"
                                                    name="id"
                                                    type="text"
                                                    disabled
                                                    value={form.values.id}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={5} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Name"
                                                    name="name"
                                                    type="text"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                    value={form.values.name}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.date && form.errors.date)}
                                                    fullWidth
                                                    helperText={form.touched.date && form.errors.date}
                                                    label="Date"
                                                    name="date"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.date}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="# of Guests"
                                                    name="numberOfAllowedGuests"
                                                    type="number"
                                                    value={form.values.numberOfAllowedGuests}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="# of Rounds"
                                                    name="roundsOfGolf"
                                                    type="number"
                                                    value={form.values.roundsOfGolf}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} style={{ padding: '16px 24px' }} >
                                        <Grid item xs={12} md={4} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">NPO</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={npo ? npo : ''} 
                                                    label="Age"
                                                    onChange={(e) => setNpo(e.target.value)}
                                                    >
                                                        {npos && npos.map((npo, key) => (
                                                            <MenuItem key={key} value={npo}>{npo.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            {host ?
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <TextField
                                                        error={Boolean(form.touched.duration && form.errors.duration)}
                                                        fullWidth
                                                        helperText={form.touched.duration && form.errors.duration}
                                                        label="Host"
                                                        name="host"
                                                        type="text"
                                                        disabled
                                                        // onBlur={form.handleBlur}
                                                        // onChange={form.handleChange}
                                                        value={host ? host.name : ''}
                                                        variant="outlined"
                                                    />
                                                    <Button onClick={() => {
                                                        setShowAddHost(true)
                                                    }}>Edit Host</Button>
                                                </Box>
                                            :
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Button variant="contained" style={{padding: '15px'}} onClick={() => {
                                                        setShowAddHost(true)
                                                    }}>Add Host</Button>
                                                </Box>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            {winner ?
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Winner"
                                                    name="winner"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={winner ? winner.name : ''}
                                                    variant="outlined"
                                                />
                                                <Button onClick={() => {
                                                        // history.push(`/app/campaigns/${hash}/winner/${campaign.winners[0].id}`)
                                                    }}>Edit Winner</Button>
                                                </Box>
                                            :
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Button variant="contained" style={{padding: '15px'}} onClick={() => {
                                                        setShowAddWinner(true)
                                                    }}>Add Winner</Button>
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Typography
                                                    color="textSecondary"
                                                >
                                                    Flow
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography>Direction</Typography>
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label=""
                                                                name="flow"
                                                                type="text"
                                                                disabled
                                                                value={form.values.flow}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {form.values.flow === 'HOST_FIRST' &&
                                                        <Grid item xs={12} md={4}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography>Host</Typography>
                                                                <TextField
                                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                                    fullWidth
                                                                    className={form.values.hostSelectedDate === true ? fillClass.green : fillClass.red}
                                                                    helperText={form.touched.duration && form.errors.duration}
                                                                    label="Has Selected Dates"
                                                                    name="hostSelectedDate"
                                                                    type="text"
                                                                    disabled
                                                                    value={form.values.hostSelectedDate}
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography>Winner</Typography>
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                className={form.values.winnerSelectedDate === true ? fillClass.green : fillClass.red}
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label="Has Selected Dates"
                                                                name="winnerSelectedDate"
                                                                type="text"
                                                                disabled
                                                                value={form.values.winnerSelectedDate}
                                                                variant="filled"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {form.values.flow === 'WINNER_FIRST' &&
                                                        <Grid item xs={12} md={4}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography>Host</Typography>
                                                                <TextField
                                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                                    fullWidth
                                                                    className={form.values.hostSelectedDate === true ? fillClass.green : fillClass.red}
                                                                    helperText={form.touched.duration && form.errors.duration}
                                                                    label="Has Selected Dates"
                                                                    name="hostSelectedDate"
                                                                    type="text"
                                                                    disabled
                                                                    value={form.values.hostSelectedDate}
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        {/*                                         
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}> */}
                                        <Grid item xs={12} md={6}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Typography

                                                    color="textSecondary"
                                                >
                                                    Options
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Includes Meal
                                                            </Typography>
                                                            <Switch
                                                                checked={includesMeal}
                                                                onChange={(e) => {
                                                                    setIncludesMeal(!includesMeal)
                                                                }}
                                                                name="includesMeal"
                                                                value={includesMeal}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Host a Second Round
                                                            </Typography>
                                                            <Switch
                                                                checked={hostASecondRound}
                                                                onChange={(e) => {
                                                                    setHostASecondRound(!hostASecondRound)
                                                                }}
                                                                name="hostAsecondRound"
                                                                value={hostASecondRound}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Includes Caddies
                                                            </Typography>
                                                            <Switch
                                                                checked={includesCaddies}
                                                                onChange={(e) => {
                                                                    setIncludesCaddies(!includesCaddies)
                                                                }}
                                                                name="includesCaddies"
                                                                value={includesCaddies}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Package Description"
                                                    name="packageDescription"
                                                    type="text"
                                                    rows={6}
                                                    multiline
                                                    value={form.values.packageDescription}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.clubRules && form.errors.clubRules)}
                                                    fullWidth
                                                    helperText={form.touched.clubRules && form.errors.clubRules}
                                                    label="Club Rules"
                                                    name="clubRules"
                                                    type="text"
                                                    rows={6}
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                    multiline
                                                    value={form.values.clubRules}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Typography variant='h4'>Location</Typography>
                                                </Box>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label="Name"
                                                                name="locationName"
                                                                type="text"
                                                                disabled
                                                                // onBlur={form.handleBlur}
                                                                // onChange={form.handleChange}
                                                                value={location ? location.name : ''}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label="Address"
                                                                name="address"
                                                                type="text"
                                                                disabled
                                                                value={location ? location.address : ''}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Button onClick={() => {
                                                        setShowAddLocation(true)
                                                    }}>{location ? "Edit Location" : "Add Location"}</Button>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Created"
                                                    name="created"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.createdAt}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.updatedAt && form.errors.updatedAt)}
                                                    fullWidth
                                                    helperText={form.touched.updatedAt && form.errors.updatedAt}
                                                    label="Last Modified"
                                                    name="modified"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.modified}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* <Box
                        mt={3}
                        mb={1}
                        >
                            {form.values.latitude !== '' &&
                                <MyMapComponent
                                    isMarkerShown
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAjdsiR85DQVbRG8xRLsjcLKQT8qsWUw3cw&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    latitude={parseFloat(form.values.latitude)}
                                    longitude={parseFloat(form.values.longitude)}
                                />
                            }
                        </Box> */}
                                    {/* </DialogContent> */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DialogActions >
                                                <Button
                                                    onClick={() => { history.goBack() }}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    disabled={form.isSubmitting}
                                                    onClick={() => {
                                                        form.submitForm();
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                        {/* </Dialog> */}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    )
                }
                }
            </Formik >
            <RouteSwitch>
                {/* <Route exact={true} path="/app/campaigns/:hash/location/" component={EditLocation} />
                <Route exact={true} path="/app/campaigns/:hash/host/:id?" component={() => EditHost(campaign ? campaign.id : 0)} />
                <Route exact={true} path="/app/campaigns/:hash/winner/:id?" component={() => EditWinner(campaign ? campaign.id : 0)} /> */}
            </RouteSwitch>
        </>
    )
}

export default CreateCampaign;