import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../constants';

export const CampaignContext = React.createContext({
    campaigns: undefined,
});


export function CampaignProvider({children}) {
    const [campaigns, setCampaigns] = useState([]);
    const [gettingData, setGettingData] = useState(true);

    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData)?.user;

    async function getCampaigns() {
        try {
            if(USER.role == 'ADMIN') {
                const data = await axios.get(`${API_URL}/campaign`);
                setCampaigns(data.data.response);
            }
            else if(USER.role == 'NPO') { 
                const data = await axios.get(`${API_URL}/npo-campaign/${USER.npoId}`);
                setCampaigns(data.data.response);
                // setCampaigns(data.data.response.filter(r => r.npo != null).filter(c => c.npo.id == USER.npoId));
            }
        }
        catch (e) {
            console.log('error', e)
        }
    }


    useEffect(() => {
        if(gettingData){
            getCampaigns();
            setGettingData(false);
        }
    }, [gettingData, USER?.role])

    return (
        <CampaignContext.Provider value={{campaigns, getCampaigns}}>
            {children}
        </CampaignContext.Provider>
    )
}


const useCampaigns = () => {
return useContext(CampaignContext);
};
  
export default useCampaigns;