import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    Paper,
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
    Checkbox,
    Divider,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Collapse,
    Switch,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import useStyles from '../Locations/styles';
import { API_URL } from '../../constants';

export const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.longitude)

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    )
}
))


function AddHost({showAddHost, setShowAddHost, host, setHost}) {
    const { campaigns, getCampaigns } = useCampaigns();
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const history = useHistory();
    const [contactPersonOpen, setContactPersonOpen] = useState(false);

    const initialValues = {
        name: host ? host.name : '',
        email: host ? host.email : '',
        phone: host ? host.phone : '',
        contactPersonName: host ? host.contactPersonName : '',
        contactPersonEmail: host ? host.contactPersonEmail : '',
        contactPersonPhone: host ? host.contactPersonPhone : '',
        clubRelation: host ? host.clubRelation : '',
        memberOfClub: host ? host.memberOfClub : false,
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)
        setHost(values)
        setShowAddHost(false)
        setSubmitting(false);
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Grid
                                container
                                spacing={3}
                                justifyContent={'center'}
                            >
                                <Dialog
                                    fullScreen={false}
                                    open={true}
                                    onClose={() => { }}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <Paper>
                                        <DialogTitle id="responsive-dialog-title">
                                            <IconButton
                                                color="inherit"
                                                onClick={() => setShowAddHost(false)}
                                                className={classNames(
                                                    classes.headerMenuButton,
                                                    classes.headerMenuButtonCollapse
                                                )}
                                                style={{ marginLeft: '0px' }}
                                            >
                                                <ArrowBackIcon
                                                    classes={{
                                                        root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                    }}
                                                />
                                            </IconButton>
                                            {"Edit Host"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="filled"
                                                        name="name"
                                                        label="Full Name"
                                                        value={form.values.name}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        error={Boolean(form.errors.name && form.touched.name)}
                                                        helperText={form.errors.name && form.touched.name && String(form.errors.name)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        variant="filled"
                                                        name="phone"
                                                        label="Phone"
                                                        value={form.values.phone}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        error={Boolean(form.errors.phone && form.touched.phone)}
                                                        helperText={form.errors.phone && form.touched.phone && String(form.errors.phone)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        variant="filled"
                                                        name="email"
                                                        label="Email"
                                                        value={form.values.email}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        error={Boolean(form.errors.email && form.touched.email)}
                                                        helperText={form.errors.email && form.touched.email && String(form.errors.email)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Paper style={{ backgroundColor: 'rgba(250, 250, 250, 1)' }}>
                                                        <Box p={2}>
                                                            <Grid container justify="space-between" alignItems="center">
                                                                <Grid item>
                                                                    <Typography variant="body1">Contact Details <span fontStyle="oblique">(if different from above)</span></Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Switch
                                                                        checked={contactPersonOpen}
                                                                        onChange={(e) => setContactPersonOpen(e.target.checked)}
                                                                        color="primary"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Collapse in={contactPersonOpen} timeout="auto" unmountOnExit fullWidth>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            variant="filled"
                                                                            name="contactPersonName"
                                                                            label="Full Name"
                                                                            value={form.values.contactPersonName}
                                                                            onChange={form.handleChange}
                                                                            onBlur={form.handleBlur}
                                                                            error={Boolean(form.errors.contactPersonName && form.touched.contactPersonName)}
                                                                            helperText={form.errors.contactPersonName && form.touched.contactPersonName && String(form.errors.contactPersonName)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <TextField
                                                                            fullWidth
                                                                            variant="filled"
                                                                            name="contactPersonPhone"
                                                                            label="Phone"
                                                                            values={form.values.contactPersonPhone}
                                                                            onChange={form.handleChange}
                                                                            onBlur={form.handleBlur}
                                                                            error={Boolean(form.errors.contactPersonPhone && form.touched.contactPersonPhone)}
                                                                            helperText={form.errors.contactPersonPhone && form.touched.contactPersonPhone && String(form.errors.contactPersonPhone)}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            variant="filled"
                                                                            name="contactPersonEmail"
                                                                            label="Email"
                                                                            value={form.values.contactPersonEmail}
                                                                            onChange={form.handleChange}
                                                                            onBlur={form.handleBlur}
                                                                            error={Boolean(form.errors.contactPersonEmail && form.touched.contactPersonEmail)}
                                                                            helperText={form.errors.contactPersonEmail && form.touched.contactPersonEmail && String(form.errors.contactPersonEmail)}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Collapse>
                                                        </Box>
                                                    </Paper>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container justify="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Typography variant="body1">Is the host a member of the club?</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Checkbox
                                                                name="memberOfClub"
                                                                value={form.values.memberOfClub}
                                                                onChange={form.handleChange}
                                                                color="primary"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {form.values.memberOfClub && (
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="clubRelationLabel">Club Relation</InputLabel>
                                                            <Select
                                                                name="clubRelation"
                                                                labelId="clubRelationLabel"
                                                                value={form.values.clubRelation}
                                                                onChange={form.handleChange}
                                                                onBlur={form.handleBlur}
                                                                error={Boolean(form.errors.clubRelation && form.touched.clubRelation)}
                                                                helperText={form.errors.clubRelation && form.touched.clubRelation && String(form.errors.clubRelation)}
                                                            >
                                                                <MenuItem value="I am a member of the golf club">Host is a member of the golf club</MenuItem>
                                                                <MenuItem value="I am staff at the golf club">Host is staff at the golf club</MenuItem>
                                                                <MenuItem value="I am not affiliated with the club">Host is not affiliated with the club</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                )}

                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => {setShowAddHost(false)}}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                color="primary"
                                                disabled={form.isSubmitting || (!form.values.email || !form.values.name)}
                                                onClick={() => {
                                                    form.submitForm();
                                                }}
                                            >
                                                Ok
                                            </Button>
                                        </DialogActions>
                                    </Paper>
                                </Dialog>
                            </Grid>
                        </form>
                    )
                }
                }
            </Formik >
        </>
    )
}

export default AddHost;