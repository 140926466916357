import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL } from '../../constants';
import {
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";


function EditNpoContact() {
    const { campaigns, getCampaigns } = useCampaigns();
    const { hash, id } = useParams();
    const [contact, setContact] = useState();
    const [serverError, setServerError] = useState('');
    const [npo, setNpo] = useState();
    const classes = useStyles();
    const history = useHistory();

    // useEffect(() => {
    //     setNpo(campaigns.filter(f => f.npo != undefined).filter(x => x.npo.contacts != null).map(campaign => campaign.npo).find(c => c.hash === hash))
    //     if(id)
    //     setContact(npo?.contacts?.map(contact => contact).find(c => c.id == id))
    // }, [campaigns, npo])

    useEffect(() => {
        const getNPOs = async() => {
            var results = await axios.get(`${API_URL}/npo`);
            if(hash){
                var npo = results.data.response.find(n=> n.hash === hash)
                setNpo(npo)
            }
        }
        getNPOs();
    }, [hash])

    useEffect(() => {
        if(npo)
        setContact(npo?.contacts?.map(c => c).find(n => n.id.toString() === id))
    }, [npo])

    console.log('npo here', npo?.contacts)
    console.log('contact here', contact)
    console.log('id here', id)

    const initialValues = {
        id: contact?.id || '',
        name: contact?.name || '',
        email: contact?.email || '',
        npoId: npo?.id || ''
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        const formValues = {
            name: values.name,
            email: values.email,
            npoId: values.npoId
        }

        try {
            if(id){
                await axios.post(`${API_URL}/npo-contact/${values.id}`, formValues)
            } else {
                await axios.post(`${API_URL}/new/npo-contact`, formValues);
            }
            getCampaigns();
        } catch (e) {
            console.log('Failed to update data with error: ', e)
        }

        setSubmitting(false);
        history.push(`/app/npos/edit/${hash}`)
        // history.goBack();
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Dialog
                                    fullScreen={false}
                                    open={true}
                                    onClose={() => { history.goBack() }}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        <IconButton
                                            color="inherit"
                                            onClick={() => history.goBack()}
                                            className={classNames(
                                                classes.headerMenuButton,
                                                classes.headerMenuButtonCollapse
                                            )}
                                            style={{ marginLeft: '0px' }}
                                        >
                                            <ArrowBackIcon
                                                classes={{
                                                    root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                }}
                                            />
                                        </IconButton>
                                        {id ? "Edit Contact" : 'New Contact'}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Name"
                                                name="name"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.name}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <img style={{position: 'relative', left: '50%', transform: 'translateX(-50%)'}} src={form.values.image} />
                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Email"
                                                name="email"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.email}
                                                variant="outlined"
                                            />
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => { history.goBack() }}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={form.isSubmitting}
                                            onClick={() => {
                                                form.submitForm();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </form>
                    )
                }
                }
            </Formik >
        </>
    )
}

export default EditNpoContact;