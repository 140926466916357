import React, { useState, useEffedt, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    Box,
    Button,
    Grid,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Typography,
} from "@material-ui/core";
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL } from '../../constants';
import {
    ArrowBack as ArrowBackIcon, FormatShapes,
} from "@material-ui/icons";
import classNames from "classnames";
import FileUpload from '../../components/Global/FileUpload';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditNpoContact from './EditNpoContact';
import { Route, Switch } from 'react-router-dom';
import { AddCircle as CircleIcon } from '@material-ui/icons';
import { useStyles as TableStyles } from "../../themes/TableStyles";


function EditNpo() {
    const { campaigns, getCampaigns } = useCampaigns();
    const { hash } = useParams();
    const [npo, setNpo] = useState();
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const tableClass = TableStyles();
    const history = useHistory();
    const [image, setImage] = useState();
    const [npoCampaigns, setNpoCampaigns] = useState();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;

    useEffect(() => {
        var approved = campaigns?.filter(n => n.npo != null).filter(n => n.npo.id == USER.npoId).find(n => n.npo.hash)
        if(USER.role == 'NPO' & hash != approved?.npo.hash){
            history.push('/app/error');
        } else {
            setNpo(campaigns.filter(f => f.npo != undefined).map(campaign => campaign.npo).find(c => c.hash === hash));
        }
    }, [campaigns])

    useEffect(() => {
        if (npo)
            setNpoCampaigns(campaigns.filter(f => f.npo != undefined).filter(n => n.npo.id == npo.id));
    }, [npo])

    const initialValues = {
        id: npo?.id || '',
        name: npo?.name || '',
        campaignName: npo?.campaignName || '',
        image: npo?.image || '',
        hash: npo?.hash || '',
        contacts: npo?.contacts || [],
    }

    function handleUpload(event) {
        if (event.target.files[0] != null) {
            setImage(event.target.files[0])
        }
    }

    const ImageThumb = ({ image, values }) => {
        return (
            <>
                {image === '' ?
                    <img src={npo?.image} className={classes.fileImage} style={{maxWidth: '300px', objectFit: 'cover'}} alt={image.name} />
                    :
                    <img src={URL.createObjectURL(image)} className={classes.fileImage} style={{maxWidth: '300px', objectFit: 'cover'}} alt={image.name} />
                }
            </>
        );
    };



    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        const formValues = {
            name: values.name,
            campaignName: values.campaignName,
        }

        const imageValue = {
            image: image
        }

        let data = new FormData();
        data.append('name', values.name)
        data.append('campaignName', values.campaignName)
        data.append('image', image)
        console.log(data)
        try {
            await axios.post(`${API_URL}/npo/${values.hash}`, data)
            getCampaigns();
        } catch (e) {
            console.log('Failed to update data with error: ', e)
        }

        setSubmitting(false);
        history.goBack();
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Dialog
                                    fullScreen={true}
                                    open={true}
                                    onClose={() => { history.goBack() }}
                                    aria-labelledby="responsive-dialog-title"
                                    fullWidth
                                >
                                    <DialogTitle id="responsive-dialog-title">
                                        <IconButton
                                            color="inherit"
                                            onClick={() => history.goBack()}
                                            className={classNames(
                                                classes.headerMenuButton,
                                                classes.headerMenuButtonCollapse
                                            )}
                                            style={{ marginLeft: '0px' }}
                                        >
                                            <ArrowBackIcon
                                                classes={{
                                                    root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                }}
                                            />
                                        </IconButton>
                                        {"Edit NPO"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <FileUpload onDrop={handleUpload} onChange={handleUpload} file={image} ImageThumb={ImageThumb} accept={"image/jpeg, image/png"} />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            {/* <img style={{position: 'relative', left: '50%', transform: 'translateX(-50%)'}} src={form.values.image} /> */}

                                            <TextField
                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                fullWidth
                                                helperText={form.touched.duration && form.errors.duration}
                                                label="Name"
                                                name="name"
                                                type="text"
                                                onBlur={form.handleBlur}
                                                onChange={form.handleChange}
                                                value={form.values.name}
                                                variant="outlined"
                                            />
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <TableContainer className={tableClass.container}>
                                                    <Box
                                                        mt={3}
                                                        mb={1}
                                                    >
                                                        <Typography variant={'h4'}>Campaigns</Typography>
                                                    </Box>
                                                    <Table className={classes.table} aria-label="simple table" stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell align="left">Location</TableCell>
                                                                <TableCell align="left">Host Name</TableCell>
                                                                <TableCell align="left">Host Email</TableCell>
                                                                <TableCell align="left">Winner Name</TableCell>
                                                                <TableCell align="left">Winner Email</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {npoCampaigns?.map((c, key) => {
                                                                return (
                                                                    <TableRow className={tableClass.cell} key={key} onClick={() => {
                                                                        history.push(`/app/campaigns/${c.hash}`)
                                                                    }} >
                                                                        <TableCell component="th" scope="row">{c.name}</TableCell>
                                                                        <TableCell align="left">{c.location?.name}</TableCell>
                                                                        <TableCell align="left">{c.hosts[0]?.name}</TableCell>
                                                                        <TableCell align="left">{c.hosts[0]?.email}</TableCell>
                                                                        <TableCell align="left">{c.winners[0]?.name}</TableCell>
                                                                        <TableCell align="left">{c.winners[0]?.email}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Box>
                                        <Box
                                            mt={3}
                                            mb={1}
                                        >
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <TableContainer>
                                                    <Box
                                                        mt={3}
                                                        mb={1}
                                                    >
                                                        <Typography variant={'h4'}>Contacts</Typography>
                                                    </Box>
                                                    <Table className={classes.table} aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell align="left">Email</TableCell>
                                                                <TableCell align="left"></TableCell>
                                                                <TableCell align="left"></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {form.values.contacts.map((c, key) => {
                                                                return (
                                                                    <TableRow className={classes.cell} key={key} >
                                                                        <TableCell component="th" scope="row">{c.name}</TableCell>
                                                                        <TableCell align="left">{c.email}</TableCell>
                                                                        <TableCell align="left" >
                                                                            <Button onClick={() => {
                                                                                history.push(`/app/npos/${form.values.hash}/contact/${c.id}`)
                                                                            }}>
                                                                                Edit
                                                                            </Button>
                                                                        </TableCell>
                                                                        <TableCell align="left" >
                                                                            <Button onClick={async () => {
                                                                                await axios.get(`${API_URL}/delete/npo-contact/${c.id}`);
                                                                                getCampaigns();
                                                                            }}>
                                                                                Delete
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                            <Button style={{ paddingTop: '20px', paddingBottom: '20px' }} onClick={() => {
                                                                history.push(`/app/npos/${form.values.hash}/contact/`)
                                                            }}>
                                                                <CircleIcon />
                                                            </Button>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => { history.goBack() }}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            disabled={form.isSubmitting}
                                            onClick={() => {
                                                form.submitForm();
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </form>
                    )
                }
                }
            </Formik >
            <Switch>
                <Route exact={true} path="/app/npos/:hash/contact/:id" component={EditNpoContact} />
                <Route exact={true} path="/app/npos/:hash/contact/" component={EditNpoContact} />
            </Switch>
        </>
    )
}

export default EditNpo;