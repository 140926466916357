import React, { useState, useEffect, useContext } from "react";
import {
    Grid,
    IconButton,
    Tooltip,
    Button,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {
    AddCircle,
} from '@material-ui/icons';
// import AddOrganization from './AddOrganization';
// import OrganizationDetail from './OrganizationDetail';
import axios from "axios";
import { AppContext } from '../../context/AppContext';
import { useParams } from 'react-router';
import useCampaigns from '../../context/CampaignContext'
import { Route, Switch, withRouter } from 'react-router-dom'
import { useHistory } from "react-router";
import EditLocation from "./EditLocation";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchBar from "material-ui-search-bar";
import { useStyles } from "../../themes/TableStyles";




const AllLocations = () => {
    const history = useHistory();
    const { campaigns } = useCampaigns();
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [data, setData] = useState(undefined);
    const [selectedOrg, setSelectedOrg] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { hash } = useParams();
    const [locations, setLocations] = useState();
    const [rows, setRows] = useState();
    const [searched, setSearched] = useState("");
    const classes = useStyles();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;


    useEffect(() => {
        if (USER.role === 'ADMIN') {
            if (campaigns) {
                const allLocations = campaigns.filter(f => f.location != null).map((c) => {
                    let location = c.location;
                    location.hash = c.hash;
                    return location;
                });
                setLocations(allLocations.filter((location, index) => {
                    return allLocations.findIndex((childLocation) => {
                        return childLocation.id == location.id
                    }) == index;
                }));
            }
        }
        else if (USER.role == 'NPO') {
            const allLocations = campaigns.filter(n => n.npo != null).filter(i => i.npo.id == USER.npoId).filter(f => f.location != null).map((c) => {
                let location = c.location;
                location.hash = c.hash;
                return location;
            });
            setLocations(allLocations.filter((location, index) => {
                return allLocations.findIndex((childLocation) => {
                    return childLocation.id == location.id
                }) == index;
            }));
        }
    }, [campaigns])

    useEffect(() => {
        setRows(locations)
    }, [locations])


    const requestSearch = (searchedVal) => {
        const filteredRows = locations.filter((row) => {
            const rowId = row.id?.toString();
            const rowName = row.name?.toLowerCase();
            const rowAddress = row.address?.toLowerCase();
            const rowHash = row.hash?.toLowerCase();
            const searchableString = rowId + rowName + rowAddress + rowHash;
            return searchableString.includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };


    return (
        <>
            <Grid container spacing={4} justify="center" alignItems="center">
                {loading && (
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                )}
                {!loading && (
                    <Grid item xs={12}>
                        <Paper>
                            <SearchBar
                                value={searched}
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                            />
                            <TableContainer className={classes.container}>
                                <Table className={classes.table} aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">Address</TableCell>
                                            <TableCell align="left">Hash</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows?.map((location, key) => (
                                            <TableRow 
                                                className={classes.cell} 
                                                key={key} 
                                                onClick={() => {
                                                    history.push(`/app/locations/edit/${location.hash}`)
                                                }}
                                            >
                                                <TableCell component="th" scope="row">{location.id}</TableCell>
                                                <TableCell align="left">{location.name}</TableCell>
                                                <TableCell align="left">{location.address}</TableCell>
                                                <TableCell align="left">{location.hash}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <Switch>
                <Route exact={true} path="/app/locations/edit/:hash?" component={EditLocation} />
            </Switch>
        </>
    );
};
export default AllLocations;
