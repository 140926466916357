import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Formik } from 'formik';
import useCampaigns from '../../context/CampaignContext';
import {
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    Paper,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    makeStyles,
    IconButton,
    Switch,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import useStyles from './styles';
import { Route, Switch as RouteSwitch, useHistory } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { API_URL, FILE_UPLOADS } from '../../constants';
import {
    ArrowBack as ArrowBackIcon,
    Send as SendIcon,
    FileCopy as CopyIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import EditLocation from "../Locations/EditLocation";
import EditHost from '../Host/EditHost';
import EditWinner from '../Winner/EditWinner';
import StatusTable from './StatusTable';
import { Check as CheckIcon } from '@material-ui/icons';

const customClass = makeStyles((theme) => ({
    red: {
        "& .MuiFilledInput-root": {
            background: "#F47174"
        }
    },
    green: {
        "& .MuiFilledInput-root": {
            background: "#ACD1AF"
        }
    },
    toggleButtonSelected: {
        borderRadius: '15px !important',
        border: '2px solid rgba(66, 142, 117, 1)',
        borderLeft: '2px solid rgba(66, 142, 117, 1) !important',
        backgroundColor: 'rgba(66, 142, 117, .1) !important',
        width: '100%',
        '&:hover': {
            backgroundColor: 'rgba(66, 142, 117, .1)'
        }
    },
}));


export const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props.longitude)

    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        >
            {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} />}
        </GoogleMap>
    )
}
))


function EditCampaign() {
    const { campaigns, getCampaigns } = useCampaigns();
    const { hash } = useParams();
    const [campaign, setCampaign] = useState();
    const [location, setLocation] = useState();
    const [serverError, setServerError] = useState('');
    const classes = useStyles();
    const fillClass = customClass();
    const history = useHistory();
    const localStorageData = localStorage.getItem('Member for a Day');
    const USER = JSON.parse(localStorageData).user;

    const [includesMeal, setIncludesMeal] = useState(false);
    const [hostASecondRound, setHostASecondRound] = useState(false);
    const [includesCaddies, setIncludesCaddies] = useState(false);
    const [showEmailWarning, setShowEmailWarning] = useState(false);
    const [emailFlowStarted, setEmailFlowStarted] = useState(false);
    const [emailFlowError, setEmailFlowError] = useState(false);
    const [emailFlowErrorMessage, setEmailFlowErrorMessage] = useState('');
    const [isCvc, setIsCvc] = useState(false);
    const [showSendInitialCvc, setShowSendInitialCvc] = useState(false);
    const [showCvcDialog, setShowCvcDialog] = useState(false);
    const [initalCvcSuccess, setInitalCvcSuccess] = useState(false);
    const [updateResult, setUpdateResult] = useState();
    const [isHandlingFulfilment, setIsHandlingFulfilment] = useState(true);
    const [isCertificate, setIsCertificate] = useState(false);
    const [rallyUpItemId, setRallyUpItemId] = useState();
    const [showDates, setShowDates] = useState();
    const [showCancelEmailFlow, setShowCancelEmailFlow] = useState(false);
    const [copied, setCopied]= useState('')
    // const { search } = useLocation();
    // const query = React.useMemo(() => new URLSearchParams(search), [search]);
    // const index = query.get('index')
    useEffect(() => {
        if (campaign && !rallyUpItemId) {
            setRallyUpItemId(campaign.rallyUpItemId)
        }
    }, [campaign])

    useEffect(() => {
        if(!campaign) {
            return
        }
        setIsCertificate(campaign.hasCertificate === 0 ? false : true)
    }, [campaign])

    useEffect(() => {
        if (!campaign){
            return
        }
        setIsHandlingFulfilment(campaign.directConnect === 0 ? true : false)
    }, [campaign])


    const updateBoolean = (numValue, stateFunction) => {
        if (numValue === 1) {
            stateFunction(true);
        } else {
            stateFunction(false);
        }
    }

    useEffect(() => {
        if (!campaign) return
        updateBoolean(campaign?.includesMeal, setIncludesMeal);
        updateBoolean(campaign?.hostASecondRound, setHostASecondRound);
        updateBoolean(campaign?.includesCaddies, setIncludesCaddies);
        if (campaign.emailFlowStarted == 1) {
            setEmailFlowStarted(true)
        }
        if (campaign.isCVC == 1) {
            setIsCvc(true)
        }
        if (campaign.initialCvcEmailsSent == 0) {
            setShowSendInitialCvc(true)
        }
    }, [campaign])

    useEffect(() => {
        var approved = campaigns?.filter(n => n.npo != null).filter(n => n.npo.id == USER.npoId).filter(a => a.hash == hash).find(f => f.hash)
        if (USER.role == 'NPO' & hash != approved?.hash) {
            history.push('/app/error');
        } else {
            setCampaign(campaigns.map(campaign => campaign).find(c => c.hash === hash));
        }
    }, [campaigns])

    const sendInitialCvcEmails = async () => {
        let winnerLink = `https://scheduling.m4ad.net/customer/${campaign.hash}`;
        let hostLink = `https://scheduling.m4ad.net/dateselection/${campaign.hash}?hostId=${campaign.hosts[0].id}`;

        const d = new Date()
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        const eDate = new Date(year + 1, month, day);
        const expirationDate = Math.floor(eDate.getTime() / 1000)

        let formValues = {
            flowDirection: campaign.flow,
            hash: campaign.hash,
            cioId: campaign.cioId,
            rallyUpCampaignId: campaign.rallyUpItemId,
            isHandlingFulfilment: isHandlingFulfilment,
            winnerEmail: campaign.winners[0].email,
            hostEmail: campaign.hosts[0].contactPersonEmail ? campaign.hosts[0].contactPersonEmail : campaign.hosts[0].email,
            eventType: 'initial_cvc',
            winnerSelectedDates: (campaign.winners[0].dates.length > 0 || campaign?.hosts[0]?.dates.filter(d => d.isSelected > 0 || d.isFinalDate > 0).length > 0) ? 'true' : 'false',
            npoContactEmail: campaign.npo.contacts[0]?.email,
            winnerName: campaign.winners[0].name,
            hostName: campaign.hosts[0].name,
            npoName: campaign.npo.name,
            locationName: campaign.location.name,
            winnerLink: winnerLink,
            hostLink: hostLink,
            campaignName: campaign.name,
            npoImage: campaign.npo.image,
            endDate: campaign.auctionEndDate,
            expirationDate: expirationDate.toDateString(),
            guestName1: campaign.winners[0].guests[0]?.name,
            guestName2: campaign.winners[0].guests[1]?.name,
            guestName3: campaign.winners[0].guests[2]?.name,
            guestName4: campaign.winners[0].guests[3]?.name,
            isCVC: isCvc,
        }
        try {
            let response = await axios.post(`${API_URL}/send-initial-cvc`, formValues)
            if (response.data.status === 'success') {
                setInitalCvcSuccess(true)
                setShowSendInitialCvc(false)
            } else if (response.data.status === 'failed') {
                setEmailFlowError(true)
                setEmailFlowErrorMessage(response.data.message)
            }
        } catch (e) {
            setEmailFlowError(true)
            setEmailFlowErrorMessage('Error connecting to scheduling api')
            console.log('Failed to update data with error: ', e)
        }
    }

    const clearSelectedDates = () => {
        axios.post(`${API_URL}/clearAllDates`, {
            winnerId: campaign.host_dates.length > 0 ? '' : campaign.winners[0].id,
            hostId: campaign.host_dates.length > 0 ? campaign.hosts[0].id : ''
        })
        getCampaigns();
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(text)

    setTimeout(()=> {
        setCopied('')
    }, 1000)
    }
    
    const startEmailFlow = async () => {
        let winnerLink = `https://scheduling.m4ad.net/customer/${campaign.hash}`;
        let hostLink = `https://scheduling.m4ad.net/dateselection/${campaign.hash}?hostId=${campaign.hosts[0].id}`;
        let winnerReschedule = `https://scheduling.m4ad.net/dateselection/${campaign.hash}?winnerId=${campaign.winners[0].id}`;

        const d = new Date()
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        const eDate = new Date(year + 1, month, day);

        function formatDate(d) {
            var year = d.getFullYear();
            var month = ("0" + (d.getMonth() + 1)).slice(-2);
            var day = ("0" + d.getDate()).slice(-2);
            return year + "-" + month + "-" + day;
        }
        let expirationDate = formatDate(eDate)

        let formValues = {
            flowDirection: campaign.flow,
            hash: campaign.hash,
            cioId: campaign.cioId,
            rallyUpCampaignId: campaign.rallyUpItemId,
            isHandlingFulfilment: isHandlingFulfilment,
            winnerId: campaign.winners[0].id,
            hostId: campaign.hosts[0].id,
            winnerEmail: campaign.winners[0].email,
            hostEmail: campaign.hosts[0].contactPersonEmail ? campaign.hosts[0].contactPersonEmail : campaign.hosts[0].email,
            eventType: 'rallyup_campaign_ended',
            winnerSelectedDates: (campaign.winners[0].dates.length > 0 || campaign?.hosts[0]?.dates.filter(d => d.isSelected > 0 || d.isFinalDate > 0).length > 0) ? 'true' : 'false',
            hostSelectedDates: (campaign.hosts[0].dates.length > 0 || campaign?.winners[0]?.dates.filter(d => d.isSelected > 0 || d.isFinalDate > 0).length > 0) ? 'true' : 'false',
            npoContactEmail: campaign.npo.contacts[0]?.email,
            npoContactName: campaign.npo.contacts[0]?.name,
            winnerName: campaign.winners[0].name,
            hostName: campaign.hosts[0].name,
            npoName: campaign.npo.name,
            locationName: campaign.location.name,
            winnerLink: winnerLink,
            hostLink: hostLink,
            winnerReschedule: winnerReschedule,
            campaignName: campaign.name,
            npoImage: campaign.npo.image,
            endDate: d.toDateString(),
            expirationDate: expirationDate,
            guestName1: campaign.winners[0].guests[0]?.name,
            guestName2: campaign.winners[0].guests[1]?.name,
            guestName3: campaign.winners[0].guests[2]?.name,
            guestName4: campaign.winners[0].guests[3]?.name,
            isCVC: isCvc,
            isCertificate: isCertificate,
            isFirst: 'false',
        }
        try {
            let response = await axios.post(`${API_URL}/start-email-flow`, formValues)
            if (response.data.status === 'success') {
                setEmailFlowStarted(true)
            } else if (response.data.status === 'failed') {
                setEmailFlowStarted(true)
                setEmailFlowError(true)
                setEmailFlowErrorMessage(response.data.message)
            }
        } catch (e) {
            setEmailFlowError(true)
            setEmailFlowErrorMessage('Error connecting to scheduling api')
            console.log('Failed to update data with error: ', e)
        }
    }

    const cancelEmailFlow = async() => {
        let formValues = {
            hash: campaign.hash,
            rallyUpCampaignId: campaign.rallyUpItemId,
        }
        try {
            let response = await axios.post(`${API_URL}/cancel-email-flow`, formValues)
            if (response.data.status === 'success') {
                setShowCancelEmailFlow(true)
            } else if (response.data.status === 'failed') {
                console.log('Failed to cancel email flow')
            }
        } catch (e) {
            console.log('Failed to cancel email flow with error: ', e)
        }
    }


    const initialValues = {
        id: campaign?.id || '',
        hash: campaign?.hash || '',
        flow: campaign?.flow.replace('COACH', 'HOST') || '',
        createdAt: campaign?.created_at || '',
        modified: campaign?.updated_at || '',
        name: campaign?.name || '',
        numberOfAllowedGuests: campaign?.numberOfAllowedGuests || 0,
        roundsOfGolf: campaign?.roundsOfGolf || 0,
        includesMeal: includesMeal || 0,
        hostASecondRound: hostASecondRound || 0,
        includesCaddies: includesCaddies || 0,
        packageDescription: campaign?.packageDescription || '',
        clubRules: campaign?.clubRules || '',
        updatedAt: campaign?.updatedAt || '',
        locationName: campaign?.location?.name || '',
        address: campaign?.location?.address || '',
        latitude: campaign?.location?.lat || '',
        longitude: campaign?.location?.long || '',
        npo: campaign?.npo ? campaign?.npo?.name : '',
        locationId: campaign?.location?.id || 0,
        timeZone: campaign?.location?.timezone || '',
        date: campaign?.hosts[0]?.dates.filter(d => d.isFinalDate === 1).map(date => date.date) || '',
        winner: campaign?.winners[0]?.name || '',
        host: campaign?.hosts[0]?.name || '',
        hostSelectedDate: !campaign?.hostSelectedDate ? 'false' : campaign?.hostSelectedDate || '',
        winnerSelectedDate: !campaign?.winnerSelectedDate ? 'false' : campaign?.winnerSelectedDate || '',
        isCvc: campaign?.isCVC,
        hasStartedEmailFlow: campaign?.emailFlowStarted,
        rallyUpItemId: campaign?.rallyUpItemId,
        bookingRestrictions: campaign?.bookingRestrictions || '',
        attributesPaidBy: campaign?.attributesPaidBy || '',
        donationType: campaign?.donationType || '',
        cashCost: campaign?.cashCost || '',
    }

    const submitData = async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true)

        const formValues = {
            modified: campaign?.updated_at || '',
            name: values.name,
            hasCertificate: isCertificate,
            numberOfAllowedGuests: values.numberOfAllowedGuests,
            roundsOfGolf: values.roundsOfGolf,
            includesMeal: includesMeal === true ? 1 : 0,
            hostASecondRound: hostASecondRound === true ? 1 : 0,
            includesCaddies: includesCaddies === true ? 1 : 0,
            packageDescription: values.packageDescription,
            clubRules: values.clubRules,
            isCvc: isCvc,
            updatedAt: new Date(),
            isHandlingFulfilment: !isHandlingFulfilment,
            rallyUpItemId: values.rallyUpItemId,
        }
        try {
            await axios.post(`${API_URL}/update/campaign/${hash}`, formValues)
            setUpdateResult('Successfully updated campaign')
        } catch (e) {
            console.log('Failed to update data with error: ', e)
            setUpdateResult(`Error updating campaign: ${e}`)
        }

        setSubmitting(false);
        getCampaigns();
        // history.goBack();
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        submitData(values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        })
                    } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setServerError(err.message);
                        setSubmitting(false);
                    }
                    resetForm();
                }}
            >
                {(form) => {
                    // formik.current = form
                    return (
                        <form
                            onSubmit={form.handleSubmit}
                        // className={clsx(classes.root, className)}
                        >
                            <Paper>
                                <Grid
                                    container
                                    // spacing={3}
                                    justifyContent={'space-between'}
                                    style={{ position: 'relative' }}
                                >
                                    <Dialog
                                        fullScreen={false}
                                        open={showCancelEmailFlow}
                                        onClose={() => setShowCancelEmailFlow(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    >
                                        <>
                                            <DialogTitle>
                                                Email Flow Canceled
                                            </DialogTitle>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => setShowCancelEmailFlow(false)}
                                                    color="primary"
                                                >
                                                    OK
                                                </Button>
                                            </DialogActions>
                                        </>
                                    </Dialog>
                                    {/* Show Dates Modal */}
                                    <Dialog
                                        fullScreen={false}
                                        open={showDates}
                                        onClose={() => setShowDates(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    >
                                        <>
                                            <DialogTitle id="responsive-dialog-title2">
                                                Selected Dates
                                            </DialogTitle>
                                            <DialogContent>
                                                <Typography>
                                                    Dates submitted by {campaign?.host_dates.length > 0 ? 'Host' : 'Winner'}
                                                </Typography>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>Duration</TableCell>
                                                            <TableCell>Selected Date</TableCell>
                                                            <TableCell>Selected Rain Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {campaign?.host_dates.length > 0 ? campaign.host_dates.map(d => (
                                                            <TableRow>
                                                                <TableCell>{d.date}</TableCell>
                                                                <TableCell>{d.duration}hrs</TableCell>
                                                                <TableCell>{d.isSelected === 1 ? <CheckIcon/> : ''}</TableCell>
                                                                <TableCell>{d.isFinalDate === 1 ? <CheckIcon/> : ''}</TableCell>
                                                            </TableRow>
                                                        )): null}
                                                        {campaign?.winner_dates.length > 0 ? campaign.winner_dates.map(d => (
                                                            <TableRow>
                                                                <TableCell>{d.date}</TableCell>
                                                                <TableCell>{d.duration}hrs</TableCell>
                                                                <TableCell>{d.isSelected === 1 ? <CheckIcon/> : ''}</TableCell>
                                                                <TableCell>{d.isFinalDate === 1 ? <CheckIcon/> : ''}</TableCell>
                                                            </TableRow>
                                                        )): null}
                                                    </TableBody>
                                                </Table>
                                            </DialogContent>
                                            <DialogActions>
                                                {campaign?.host_dates.find(d => d.isSelected === 1 || d.isFinalDate === 1) !== undefined || campaign?.winner_dates.find(d => d.isSelected === 1 || d.isFinalDate === 1) !== undefined ?
                                                    <Button
                                                        onClick={clearSelectedDates}
                                                        color="primary"
                                                    >
                                                        Clear Selected Dates
                                                    </Button>
                                                :null
                                                }
                                                
                                                <Button
                                                    onClick={() => setShowDates(false)}
                                                    color="primary"
                                                >
                                                    OK
                                                </Button>
                                            </DialogActions>
                                        </>
                                    </Dialog>

                                    {/* Update Result Modal */}
                                    <Dialog
                                        fullScreen={false}
                                        open={updateResult && updateResult !== ''}
                                        onClose={() => setUpdateResult()}
                                        aria-labelledby="responsive-dialog-title2"
                                    >
                                        <>
                                            <DialogTitle id="responsive-dialog-title2">
                                                {updateResult}
                                            </DialogTitle>
                                            <DialogActions>
                                                <Button
                                                    onClick={() => setUpdateResult()}
                                                    color="primary"
                                                >
                                                    OK
                                                </Button>
                                            </DialogActions>
                                        </>
                                    </Dialog>

                                    {/* CVC Modal */}
                                    <Dialog
                                        fullScreen={false}
                                        open={showCvcDialog}
                                        onClose={() => setShowCvcDialog(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    >
                                        {!initalCvcSuccess ?
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    Are you sure you want to send the initial CVC emails for this campaign?
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => setShowCvcDialog(false)}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            sendInitialCvcEmails()
                                                        }}
                                                    >
                                                        Send Initial CVC emails
                                                    </Button>
                                                </DialogActions>
                                            </>
                                            : null}

                                        {!emailFlowError && initalCvcSuccess ? (
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    Initial CVC emails sent for {initialValues.name}!
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => setShowCvcDialog(false)}
                                                        color="primary"
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </>
                                        ) : null}
                                        {emailFlowError && initalCvcSuccess ? (
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    Something went wrong when trying to start the email flow with error:
                                                    {emailFlowErrorMessage}
                                                    <br />
                                                    <br />
                                                    Please try again.
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => { setShowEmailWarning(false); setInitalCvcSuccess(false) }}
                                                        color="primary"
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </>
                                        ) : null}
                                    </Dialog>

                                    {/* Email Flow Modal */}
                                    <Dialog
                                        fullScreen={false}
                                        open={showEmailWarning}
                                        onClose={() => setShowEmailWarning(false)}
                                        aria-labelledby="responsive-dialog-title2"
                                    // fullWidth
                                    >
                                        {!emailFlowStarted ? (
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    Are you sure you want to start the email flow for this campaign?
                                                </DialogTitle>
                                                <Grid container justifyContent='flex-end'>
                                                    <>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                label={'Is CVC'}
                                                                style={{ margin: 'auto', paddingRight: '25px' }}
                                                                control={<Checkbox
                                                                    checked={isCvc}
                                                                    onChange={() => setIsCvc(!isCvc)}
                                                                />}
                                                            />
                                                        </Grid>
                                                        <Grid item >
                                                            <FormControlLabel
                                                                label={'We are handling fulfillment'}
                                                                style={{ margin: 'auto', paddingRight: '25px' }}
                                                                control={<Checkbox
                                                                    checked={isHandlingFulfilment}
                                                                    onChange={() => setIsHandlingFulfilment(!isHandlingFulfilment)}
                                                                />}
                                                            />
                                                        </Grid>
                                                        <Grid item >
                                                            <FormControlLabel
                                                                label={'Has Certificate'}
                                                                style={{ margin: 'auto', paddingRight: '25px' }}
                                                                control={<Checkbox
                                                                    checked={isCertificate}
                                                                    onChange={() => setIsCertificate(!isCertificate)}
                                                                />}
                                                            />
                                                        </Grid>
                                                    </>
                                                </Grid>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => setShowEmailWarning(false)}
                                                        color="primary"
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            startEmailFlow()
                                                        }}
                                                    >
                                                        Start Email Flow
                                                    </Button>
                                                </DialogActions>
                                            </>
                                        ) : null}
                                        {!emailFlowError && emailFlowStarted ? (
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    Email flow for {initialValues.name} has been started!
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => setShowEmailWarning(false)}
                                                        color="primary"
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </>
                                        ) : null}
                                        {emailFlowError && emailFlowStarted ? (
                                            <>
                                                <DialogTitle id="responsive-dialog-title2">
                                                    Something went wrong when trying to start the email flow with error:
                                                    {emailFlowErrorMessage}
                                                    <br />
                                                    <br />
                                                    Please try again.
                                                </DialogTitle>
                                                <DialogActions>
                                                    <Button
                                                        onClick={() => { setShowEmailWarning(false); setEmailFlowStarted(false) }}
                                                        color="primary"
                                                    >
                                                        OK
                                                    </Button>
                                                </DialogActions>
                                            </>
                                        ) : null}
                                    </Dialog>

                                    {/* Form */}
                                    <Grid item xs={12}>
                                        <DialogTitle id="responsive-dialog-title">
                                            <Grid container spacing={3} justifyContent={'space-between'}>
                                                <Grid item xs={12} md={4}>
                                                    <IconButton
                                                        color="inherit"
                                                        onClick={() => history.push(`/app/campaigns`)}
                                                        className={classNames(
                                                            classes.headerMenuButton,
                                                            classes.headerMenuButtonCollapse
                                                        )}
                                                        style={{ marginLeft: '0px' }}
                                                    >
                                                        <ArrowBackIcon
                                                            classes={{
                                                                root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                            }}
                                                        />
                                                    </IconButton>{"Edit Campaign"}
                                                </Grid>
                                                {/* <Grid item xs={12} md={2} style={{ justifyContent: 'center', textAlign: 'center', margin: 'auto' }}>
                                                    {form.values.isCVC || isCvc && showSendInitialCvc ?
                                                        <div style={{ flex: 1 }}>
                                                            {'Send Initial CVC emails'}
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={() => setShowCvcDialog(true)}
                                                                className={classNames(
                                                                    classes.headerMenuButton,
                                                                    classes.headerMenuButtonCollapse
                                                                )}
                                                                style={{ marginLeft: '10px' }}
                                                            >
                                                                <SendIcon
                                                                    classes={{
                                                                        root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                        : null}
                                                </Grid> */}
                                                <Grid item xs={12} md={2}>
                                                    {form.values.host !== '' && form.values.winner !== '' && !form.values.hasStartedEmailFlow ?
                                                        <div style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                                                            {'Start Email Flow'}
                                                            <IconButton
                                                                color="inherit"
                                                                onClick={() => setShowEmailWarning(true)}
                                                                className={classNames(
                                                                    classes.headerMenuButton,
                                                                    classes.headerMenuButtonCollapse
                                                                )}
                                                                style={{ marginLeft: '10px' }}
                                                            >
                                                                <SendIcon
                                                                    classes={{
                                                                        root: classNames(classes.headerIcon, classes.headerIconCollapse)
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </div>
                                                        : null}
                                                </Grid>
                                            </Grid>
                                        </DialogTitle>
                                    </Grid>
                                    {/* <DialogContent> */}
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={1} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="ID"
                                                    name="id"
                                                    type="text"
                                                    disabled
                                                    value={form.values.id}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            error={Boolean(form.touched.duration && form.errors.duration)}
                                                            fullWidth
                                                            helperText={form.touched.duration && form.errors.duration}
                                                            label="hash"
                                                            name="hash"
                                                            type="text"
                                                            disabled
                                                            value={form.values.hash}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button onClick={() => copyText(form.values.hash)} style={{width: 'calc(100% - 10px)', marginLeft: '10px'}}>
                                                            {copied === form.values.hash ? (
                                                                // <Typography style={{color: 'green'}}>Copied!</Typography>
                                                                <CheckIcon />
                                                            ):<CopyIcon style={{margin: 10}} />}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            error={Boolean(form.touched.duration && form.errors.duration)}
                                                            fullWidth
                                                            helperText={form.touched.duration && form.errors.duration}
                                                            label="Customer Url"
                                                            name="hash"
                                                            type="text"
                                                            disabled
                                                            value={`https://scheduling.m4ad.net/customer/${form.values.hash}`}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button onClick={() => copyText(`https://scheduling.m4ad.net/customer/${form.values.hash}`)} style={{width: 'calc(100% - 10px)', marginLeft: '10px'}}>
                                                            {copied === `https://scheduling.m4ad.net/customer/${form.values.hash}` ? (
                                                                // <Typography style={{color: 'green'}}>Copied!</Typography>
                                                                <CheckIcon />
                                                            ):<CopyIcon style={{margin: 10}} />}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={8} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Name"
                                                    name="name"
                                                    type="text"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                    value={form.values.name}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.date && form.errors.date)}
                                                    fullWidth
                                                    helperText={form.touched.date && form.errors.date}
                                                    label="Date"
                                                    name="date"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.date}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        {/* <Grid item xs={12} md={3} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    // error={Boolean(form.touched.rallyUpItemId && form.errors.rallyUpItemId)}
                                                    fullWidth
                                                    // helperText={form.touched.date && form.errors.date}
                                                    label="Rally Up Item ID"
                                                    name="rallyUpItemId"
                                                    type="text"
                                                    disabled={(rallyUpItemId === null && rallyUpItemId != 0) || (rallyUpItemId === undefined && rallyUpItemId != 0) || (rallyUpItemId !== '' && rallyUpItemId != 0)}
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                    value={form.values.rallyUpItemId}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid> */}
                                    </Grid>

                                    <Grid container spacing={3} style={{padding: '16px 24px'}}>
                                        <Grid item xs={12} md={1}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="# of Guests"
                                                    name="numberOfAllowedGuests"
                                                    type="number"
                                                    value={form.values.numberOfAllowedGuests}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="# of Rounds"
                                                    name="roundsOfGolf"
                                                    type="number"
                                                    value={form.values.roundsOfGolf}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Grid container spacing={3} style={{padding: '16px 24px'}}>
                                                {campaign?.donationType === "" || campaign?.donationType === null ? 
                                                    <>
                                                        <Grid item xs={12} md={3}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textSecondary"
                                                                >
                                                                    Is CVC
                                                                </Typography>
                                                                <Switch
                                                                    checked={isCvc}
                                                                    onChange={(e) => {
                                                                        setIsCvc(!isCvc)
                                                                    }}
                                                                    name="isCvc"
                                                                    value={isCvc}
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textSecondary"
                                                                >
                                                                    Has Certificate
                                                                </Typography>
                                                                <Switch
                                                                    checked={isCertificate}
                                                                    onChange={(e) => {
                                                                        setIsCertificate(!isCertificate)
                                                                    }}
                                                                    name="isCertificate"
                                                                    value={isCertificate}
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    color="textSecondary"
                                                                >
                                                                    Direct Connect
                                                                </Typography>
                                                                <Switch
                                                                    checked={!isHandlingFulfilment}
                                                                    onChange={(e) => {
                                                                        setIsHandlingFulfilment(!isHandlingFulfilment)
                                                                    }}
                                                                    name="isHandlingFulfilment"
                                                                    value={isHandlingFulfilment}
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </>
                                            : 
                                                <>
                                                    <Grid item xs={12} md={3}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <TextField
                                                                error={Boolean(form.touched.donationType && form.errors.donationType)}
                                                                fullWidth
                                                                helperText={form.touched.donationType && form.errors.donationType}
                                                                label="Donation Type"
                                                                name="donationType"
                                                                type="text"
                                                                disabled
                                                                // onBlur={form.handleBlur}
                                                                // onChange={form.handleChange}
                                                                value={form.values.donationType}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </>
                                            }
                                                    <Grid item xs={12} md={3}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Email Flow Started
                                                            </Typography>
                                                            <Switch
                                                                checked={form.values.hasStartedEmailFlow}
                                                                disabled
                                                                name="emailFlowStarted"
                                                                value={form.values.hasStartedEmailFlow}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {/* <Grid item xs={12} md={3}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Button disabled={form.values.hasStartedEmailFlow !== 1} onClick={cancelEmailFlow}>Cancel Email Flow</Button>
                                                        </Box>
                                                    </Grid> */}
                                                </Grid>
                                            </Paper>
                                        </Grid>                        
                                    </Grid>

                                    <Grid container spacing={3} style={{ padding: '16px 24px' }} >
                                        <Grid item xs={12} md={4} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="NPO"
                                                    name="npo"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.npo}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            {form.values.host ?
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <TextField
                                                        error={Boolean(form.touched.duration && form.errors.duration)}
                                                        fullWidth
                                                        helperText={form.touched.duration && form.errors.duration}
                                                        label="Host"
                                                        name="host"
                                                        type="text"
                                                        disabled
                                                        // onBlur={form.handleBlur}
                                                        // onChange={form.handleChange}
                                                        value={form.values.host}
                                                        variant="outlined"
                                                    />
                                                    <Button onClick={() => {
                                                        history.push(`/app/campaigns/${hash}/host/${campaign.hosts[0].id}`)
                                                    }}>Edit Host</Button>
                                                </Box>
                                                :
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Button variant="contained" style={{ padding: '15px' }} onClick={() => {
                                                        history.push(`/app/campaigns/${hash}/host`)
                                                    }}>Add Host</Button>
                                                </Box>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={4} >
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Winner"
                                                    name="winner"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.winner}
                                                    variant="outlined"
                                                />
                                                <Button onClick={() => {
                                                    if (campaign.winners.length > 0) {
                                                        history.push(`/app/campaigns/${hash}/winner/${campaign.winners[0].id}`)
                                                    } else {
                                                        history.push(`/app/campaigns/${hash}/winner`)
                                                    }
                                                }}>Edit Winner</Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={7}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Typography
                                                    color="textSecondary"
                                                >
                                                    Flow
                                                </Typography>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography>Direction</Typography>
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label=""
                                                                name="flow"
                                                                type="text"
                                                                disabled
                                                                value={form.values.flow}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {form.values.flow === 'HOST_FIRST' &&
                                                        <Grid item xs={12} md={4}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography>Host</Typography>
                                                                <TextField
                                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                                    fullWidth
                                                                    className={form.values.hostSelectedDate === true ? fillClass.green : fillClass.red}
                                                                    helperText={form.touched.duration && form.errors.duration}
                                                                    label="Has Selected Dates"
                                                                    name="hostSelectedDate"
                                                                    type="text"
                                                                    disabled
                                                                    value={form.values.hostSelectedDate}
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography>Winner</Typography>
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                className={form.values.winnerSelectedDate === true ? fillClass.green : fillClass.red}
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label="Has Selected Dates"
                                                                name="winnerSelectedDate"
                                                                type="text"
                                                                disabled
                                                                value={form.values.winnerSelectedDate}
                                                                variant="filled"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {form.values.flow === 'WINNER_FIRST' &&
                                                        <Grid item xs={12} md={4}>
                                                            <Box
                                                                mt={3}
                                                                mb={1}
                                                            >
                                                                <Typography>Host</Typography>
                                                                <TextField
                                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                                    fullWidth
                                                                    className={form.values.hostSelectedDate === true ? fillClass.green : fillClass.red}
                                                                    helperText={form.touched.duration && form.errors.duration}
                                                                    label="Has Selected Dates"
                                                                    name="hostSelectedDate"
                                                                    type="text"
                                                                    disabled
                                                                    value={form.values.hostSelectedDate}
                                                                    variant="filled"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                {campaign?.cio_status.length > 0 ?
                                                    <>
                                                        <Typography>Email Flow Status: </Typography>
                                                        <StatusTable dataRows={campaign?.cio_status} />
                                                    </>
                                                :null}
                                            </Paper>
                                            <Button onClick={() => setShowDates(true)}>View Dates</Button>
                                        </Grid>
                                        {/*                                         
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}> */}
                                        <Grid item xs={12} md={5}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Typography
                                                    color="textSecondary"
                                                >
                                                    Add-ons
                                                </Typography>
                                                {/* <Grid container spacing={3}>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Includes Meal
                                                            </Typography>
                                                            <Switch
                                                                checked={includesMeal}
                                                                onChange={(e) => {
                                                                    setIncludesMeal(!includesMeal)
                                                                }}
                                                                name="includesMeal"
                                                                value={includesMeal}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Host a Second Round
                                                            </Typography>
                                                            <Switch
                                                                checked={hostASecondRound}
                                                                onChange={(e) => {
                                                                    setHostASecondRound(!hostASecondRound)
                                                                }}
                                                                name="hostAsecondRound"
                                                                value={hostASecondRound}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color="textSecondary"
                                                            >
                                                                Includes Caddies
                                                            </Typography>
                                                            <Switch
                                                                checked={includesCaddies}
                                                                onChange={(e) => {
                                                                    setIncludesCaddies(!includesCaddies)
                                                                }}
                                                                name="includesCaddies"
                                                                value={includesCaddies}
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid> */}
                                                <ToggleButtonGroup
                                                    // value={campaign?.addons?.split(',')}
                                                    exclusive
                                                    disabled
                                                    aria-label="multi-select"
                                                    style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px'}}
                                                >
                                                    {campaign?.addons?.split(',').map(a => (
                                                        <ToggleButton 
                                                            value={a}
                                                            className={fillClass.toggleButtonSelected}
                                                        >
                                                            {a}
                                                        </ToggleButton>
                                                    ))}
                                                    {campaign?.includesMeal === 1 ?
                                                        <ToggleButton 
                                                            value={'Lunch'}
                                                            className={fillClass.toggleButtonSelected}
                                                        >
                                                            {'Lunch'}
                                                        </ToggleButton>
                                                    :null}
                                                    {campaign?.includesCaddies === 1 ?
                                                        <ToggleButton 
                                                            value={'Caddies'}
                                                            className={fillClass.toggleButtonSelected}
                                                        >
                                                            {'Caddies'}
                                                        </ToggleButton>
                                                    :null}
                                                </ToggleButtonGroup>
                                                {console.log('LOOK', campaign?.roundAttributes)}
                                                {campaign?.roundAttributes !== '' && campaign?.roundAttributes !== null ?
                                                    <>
                                                        <Typography
                                                            color="textSecondary"
                                                        >
                                                            Round Attributes
                                                        </Typography>
                                                        <ToggleButtonGroup
                                                            // value={campaign?.addons?.split(',')}
                                                            exclusive
                                                            disabled
                                                            aria-label="multi-select"
                                                            style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px'}}
                                                        >
                                                            {campaign?.roundAttributes?.split(',').map(a => (
                                                                <ToggleButton 
                                                                    value={a}
                                                                    className={fillClass.toggleButtonSelected}
                                                                >
                                                                    {a}
                                                                </ToggleButton>
                                                            ))}
                                                        </ToggleButtonGroup>
                                                        <Typography
                                                            color="textSecondary"
                                                        >
                                                            Attributes paid for by
                                                        </Typography>
                                                        <RadioGroup
                                                            aria-labelledby="attributesPaidBy-radio-buttons-group-label"
                                                            defaultValue=""
                                                            row
                                                            disabled
                                                            name="attributesPaidBy"
                                                            value={form.values.attributesPaidBy}
                                                            // onChange={form.values.handleChange}
                                                        >
                                                            <FormControlLabel value="donor" control={<Radio />} label="Donor" />
                                                            <FormControlLabel value="winner" control={<Radio />} label="Winner" />
                                                        </RadioGroup>
                                                    </>
                                                :null}
                                                

                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Package Description"
                                                    name="packageDescription"
                                                    type="text"
                                                    rows={6}
                                                    multiline
                                                    value={form.values.packageDescription}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.clubRules && form.errors.clubRules)}
                                                    fullWidth
                                                    helperText={form.touched.clubRules && form.errors.clubRules}
                                                    label="Club Rules"
                                                    name="clubRules"
                                                    type="text"
                                                    rows={6}
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                    multiline
                                                    value={form.values.clubRules}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    fullWidth
                                                    helperText={form.touched.bookingRestrictions && form.errors.bookingRestrictions}
                                                    label="Booking Restrictions"
                                                    name="bookingRestrictions"
                                                    type="text"
                                                    rows={3}
                                                    // disabled
                                                    multiline
                                                    value={form.values.bookingRestrictions}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.cashCost && form.errors.cashCost)}
                                                    fullWidth
                                                    helperText={form.touched.cashCost && form.errors.cashCost}
                                                    label="Cash Cost"
                                                    name="cashCost"
                                                    type="text"
                                                    disabled
                                                    InputProps={{
                                                        startAdornment: '$',
                                                    }}
                                                    value={form.values.cashCost}
                                                    variant="outlined"
                                                    onBlur={form.handleBlur}
                                                    onChange={form.handleChange}
                                                />
                                            </Box>
                                        </Grid>
                                        {campaign?.certificateFile ?
                                            <Grid item xs={12} md={6}>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                <Typography
                                                    color="textSecondary"
                                                >
                                                    Certificate
                                                </Typography>
                                                    <a href={`${FILE_UPLOADS}/${campaign?.certificateFile}`} target="_blank">
                                                        {campaign?.certificateFile.includes('jpg') || campaign?.certificateFile.includes('png') || campaign?.certificateFile.includes('jpeg') ?
                                                            <img src={`${FILE_UPLOADS}/${campaign?.certificateFile}`} />
                                                        :'Certificate File'}
                                                    </a>
                                                </Box>
                                            </Grid>
                                        :null}
                                        
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Paper variant='outlined' style={{ padding: '20px', marginTop: '10px' }}>
                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Typography variant='h4'>Location</Typography>
                                                </Box>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label="Name"
                                                                name="locationName"
                                                                type="text"
                                                                disabled
                                                                // onBlur={form.handleBlur}
                                                                // onChange={form.handleChange}
                                                                value={form.values.locationName}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box
                                                            mt={3}
                                                            mb={1}
                                                        >
                                                            <TextField
                                                                error={Boolean(form.touched.duration && form.errors.duration)}
                                                                fullWidth
                                                                helperText={form.touched.duration && form.errors.duration}
                                                                label="Address"
                                                                name="address"
                                                                type="text"
                                                                disabled
                                                                value={form.values.address}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Box
                                                    mt={3}
                                                    mb={1}
                                                >
                                                    <Button onClick={() => {
                                                        history.push(`/app/campaigns/${hash}/location`)
                                                    }}>Edit Location</Button>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3} style={{ padding: '16px 24px' }}>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.duration && form.errors.duration)}
                                                    fullWidth
                                                    helperText={form.touched.duration && form.errors.duration}
                                                    label="Created"
                                                    name="created"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.createdAt}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box
                                                mt={3}
                                                mb={1}
                                            >
                                                <TextField
                                                    error={Boolean(form.touched.updatedAt && form.errors.updatedAt)}
                                                    fullWidth
                                                    helperText={form.touched.updatedAt && form.errors.updatedAt}
                                                    label="Last Modified"
                                                    name="modified"
                                                    type="text"
                                                    disabled
                                                    // onBlur={form.handleBlur}
                                                    // onChange={form.handleChange}
                                                    value={form.values.modified}
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* <Box
                        mt={3}
                        mb={1}
                        >
                            {form.values.latitude !== '' &&
                                <MyMapComponent
                                    isMarkerShown
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAjdsiR85DQVbRG8xRLsjcLKQT8qsWUw3cw&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    latitude={parseFloat(form.values.latitude)}
                                    longitude={parseFloat(form.values.longitude)}
                                />
                            }
                        </Box> */}
                                    {/* </DialogContent> */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <DialogActions >
                                                <Button
                                                    onClick={() => { history.goBack() }}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    disabled={form.isSubmitting}
                                                    onClick={() => {
                                                        form.submitForm();
                                                    }}
                                                >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Grid>
                                        {/* </Dialog> */}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </form>
                    )
                }
                }
            </Formik >
            <RouteSwitch>
                <Route exact={true} path="/app/campaigns/:hash/location/" component={EditLocation} />
                <Route exact={true} path="/app/campaigns/:hash/host/:id?" component={() => EditHost(campaign ? campaign.id : 0)} />
                <Route exact={true} path="/app/campaigns/:hash/winner/:id?" component={() => EditWinner(campaign ? campaign.id : 0)} />
            </RouteSwitch>
        </>
    )
}

export default EditCampaign;