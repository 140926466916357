import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        maxHeight: '80vh',
    },
    table: {
        minWidth: 650,
    },
    cell: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#F6F7FF',
        }
    },
    mergedCell: {
        '&:hover': {
            // backgroundColor: '#F6F7FF',
        }
    }
});