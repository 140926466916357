import React from "react";
import axios from "axios";
import { API_URL } from '../constants';
// import jwt from "jsonwebtoken";

//config
// import config from "../../src/config";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  let loggedIn = false;
  const localStorageData = localStorage.getItem('Member for a Day');
  if (localStorageData) {
    const { token, user } = JSON.parse(localStorageData);
    // console.log(user)
    if (token) {
      loggedIn = true;
    }
  }

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: loggedIn
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  social = ""
) {
  setError(false);
  setIsLoading(true);

  // We check if app runs with backend mode
  // if (!config.isBackend) {
  //   setTimeout(() => {
  //     setError(null);
  //     setIsLoading(false);
  //     receiveToken("token", dispatch);
  //   }, 2000);
  // } else {
  //   if (!!social) {
  //     window.location.href =
  //       config.baseURLApi +
  //       "/user/signin/" +
  //       social +
  //       (process.env.NODE_ENV === "production"
  //         ? "?app=https://flatlogic.github.io/react-material-admin-full"
  //         : "");
  //   } else if (login.length > 0 && password.length > 0) {
  //     axios
  //       .post("/user/signin/local", { email: login, password })
  //       .then(res => {
  //         const token = res.data.token;
  //         setTimeout(() => {
  //           setError(null);
  //           setIsLoading(false);
  //           receiveToken(token, dispatch);
  //         }, 2000);
  //       })
  //       .catch(() => {
  //         setError(true);
  //         setIsLoading(false);
  //       });
  //   } else {
  //     dispatch({ type: "LOGIN_FAILURE" });
  //   }
  // }

  try {
    const data = {
      email: login,
      password: password
    };

    // Get TOKEN AND USER DATA HERE

    const response = await axios.post(`${API_URL}/auth/login`, data);
    const token = response.data.response.token;
    const name = response.data.response.name;
    const user = {
      id: response.data.response.id,
      name: response.data.response.name,
      email: response.data.response.email,
      role: response.data.response.role,
      npoId: response.data.response.npoId,
    }

    receiveToken(token, user, dispatch);

    // let permissions: IPermission[] = [];
    // if (user.permissions) {
    //   permissions = user.permissions;
    // }

    // // Multiple organizations will be returned. Loop over them and
    // // find the default one to load first.
    // const organizations = response.data.response.organizations;

    // const organization = organizations.find(
    //   (org: IOrganization) => org.default === 1
    // );

    // const orgId = organization.organization_id;

    // Load the rest of the user info
    // const {
    //   routes,
    //   socialMediaAccounts,
    //   invitation,
    //   errors
    // } = await loadUser(token, orgId);

    // if (errors && Object.keys(errors).length > 0) {
    //   toast.error("Permission denied.");
    //   window.location.href = errors?.valid_url ?? window.location.href;
    //   return;
    // }

    // actions.setStatus("");
    // actions.setSubmitting(false);
    // await setAuth({ token, orgId }, routes, permissions, socialMediaAccounts);
  } catch (error) {
    // toast.error("Permission denied.");
    // actions.setStatus(error.errorMessage);
    // actions.setSubmitting(false);
    // dispatch({ type: "LOGIN_FAILURE" }); 
    console.log(`failed to login with error: `, error);
    setError(true);
    setIsLoading(false);
  }
}

async function signOut(dispatch, history) {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem('Member for a Day');
  document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  axios.defaults.headers.common["Authorization"] = "";
  axios.defaults.headers.common["Organization-Id"] = "";
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  await axios.get(`${API_URL}/logout`)
  history.push("/login");
}

export function receiveToken(token, user, dispatch) {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem(
    'Member for a Day',
    JSON.stringify({ token: token, user: user })
  );

  localStorage.setItem("theme", "default");
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["Organization-Id"] = 1;
  dispatch({ type: "LOGIN_SUCCESS" });
}
